import { useState, useEffect } from 'react';
import useLocalStorage from "../hooks/useLocalStorage";
import {BASE_GET_OPTIONS, BASE_URL, BASE_POST_OPTIONS} from "./static_vars";
import {useFabApp} from "../hooks/useFabApp";
import {Tag} from "../interfaces/Tag";

export function useCustomerLogoApiSpe() {
    const [tokenLocalItemValue] = useLocalStorage("fabToken", "");
    const sendNewLogo = async (newLogo: File) => {
        if (!newLogo) return
        try {
            const opts = BASE_POST_OPTIONS;
            opts.headers = new Headers()
            opts.headers.append('Authorization', `Bearer ${tokenLocalItemValue}`)
            opts.body = new FormData()
            opts.body.append('customer_logo', newLogo)
            const response = await fetch(BASE_URL + 'web/dashboard/post_logo', opts);
            if (!response.ok || response.body === null) {
                throw new Error("Erreur lors de l'envoi de l'image");
            }
            console.log(response)
            return;
        } catch(e: any) {
            console.log(e)
        }
    }
    return { sendNewLogo};
}