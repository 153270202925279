// noinspection JSUnresolvedReference

import React, {useState} from "react"
import {
    GridRowModesModel,
} from '@mui/x-data-grid';
import {FDataGrid} from "../ui/elements/FDataGrid";
import { useTagsApi } from "../../api/base_apis";
import {FModal} from "../ui/elements/FModal";

export const TagsPage = () => {
    const {data: tags, loading, error, getItemById: getTagWithValues} = useTagsApi();
    const [modalShowMoreOpen, setModalShowMoreOpen] = useState<boolean>(false)
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [fetchedTag, setFetchedTag] = useState<any | null>(null)
    const columns: any[] = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'identifier', headerName: 'TID', type: 'string', width: 380, editable:true},
        { field: 'rfidValue', headerName: 'EPC', type: 'string', width: 380, editable:true},
        { field: 'EncodingFormat', headerName: 'Encodage', width: 380, valueGetter: (params: any) => params.label },
    ]
    const showOnTag = (id: number) => {
        setModalShowMoreOpen(true)
        const fetchTag = getTagWithValues(id)
        setFetchedTag(fetchTag)
    }
    const onCloseModal = () => {
        setModalShowMoreOpen(false)
    }
    return (
        <>
            <div className="w-full h-full bg-fab_app_container flex flex-col">
                <div className="panel_page_entete">
                    <span className="text-2xl m-auto">TAGS</span>
                </div>

                {error &&
                    <div
                        className="text-white bg-fab_light_red-400 bg-opacity-75 rounded-md p-2 h-10 w-10/12 flex justify-center">
                        <p className="text-ellipsis overflow-hidden text-center">{error.message}</p>
                    </div>
                }
                {rowModesModel &&
                    <div className="w-full flex-1">
                        <FDataGrid
                            disableEdit
                            rowModesModel={rowModesModel}
                            setRowModesModel={setRowModesModel}
                            rows={tags}
                            loading={loading}
                            columns={columns}
                        />
                    </div>
                }
            </div>
            {
                modalShowMoreOpen &&
                <FModal
                    className="bg-white w-8/12 h-4/6 rounded-md m-auto flex flex-col justify-center text-center p-3 shadow-2xl"
                    canClose setModalOpen={setModalShowMoreOpen} onClose={onCloseModal}
                >
                    {!fetchedTag ?
                        <>
                            <p className="text-xl">Chargement ...</p>
                        </>
                        :
                        <>
                            <p className="text-xl">{fetchedTag.identifier}</p>
                            {
                                fetchedTag.ValueInfos?.map((value: any) => {
                                    return (
                                        value.InfoType && <div className="flex w-full gap-2">
                                            <p>{value.InfoType?.label}</p>
                                            <p>{value.InfoValue?.label}</p>
                                            <p>{value.createdAt}</p>
                                        </div>
                                    )
                                })
                            }
                        </>
                    }
                </FModal>
            }
        </>
    )
}