import { useState, useEffect } from 'react';
import useLocalStorage from "../hooks/useLocalStorage";
import { BASE_GET_OPTIONS, BASE_URL, BASE_PUT_OPTIONS, BASE_DELETE_OPTION, BASE_POST_OPTIONS } from "./static_vars";
import {Identifiable} from "../interfaces/Identifiable";
import {useFabApp} from "../hooks/useFabApp";

interface UseApiOptions<T extends Identifiable> {
    endpoint: string;
    siteDependant?: boolean;
    fabOrderTypeDependant?: boolean;
    specificIdentifierDependant?: boolean;
    onDelete?: (id: number) => void;
}
export function useApi<T extends Identifiable>({ endpoint, siteDependant, fabOrderTypeDependant, specificIdentifierDependant, onDelete }: UseApiOptions<T>): {
    resetApiError: () => void;
    addItem: (element: T) => Promise<void>;
    data: T[];
    deleteItem: (id: number) => Promise<void>;
    getItemById: (id: number) => (T | undefined);
    updateItem: <U extends Identifiable>(element: U) => Promise<U | undefined>;
    loading: boolean;
    error: Error | null;
    refreshItems: () => Promise<void>
} {
    const [data, setData] = useState<T[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const [tokenLocalItemValue] = useLocalStorage("fabToken", "");
    const {siteChosen, fabOrderTypeChosen, specificIdentifierChosen} = useFabApp()
    const getItemById = (id: number): T | undefined => {
        return data.find(item => item.id == id);
    };
    const resetApiError = () => {
        setError(null)
    }
    const addItem = async (element: T) => {
        try {
            setLoading(true);
            const opts = { ...BASE_POST_OPTIONS };
            opts.headers = new Headers();
            opts.headers.append("Authorization", `Bearer ${tokenLocalItemValue}`);
            opts.body = new FormData();
            opts.body.append('newItem', JSON.stringify(element));
            const response = await fetch(`${BASE_URL}${endpoint}`, opts);
            if (!response.ok || response.body === null) {
                throw new Error(response.statusText ?? 'Erreur lors de l\'ajout de l\'élément');
            } else {
                const data = await response.json();
                setLoading(false);
                setData(data);
            }
        } catch (e: any) {
            setLoading(false);
            setError(e);
        }
    };
    const deleteItem = async (id: number) => {
        try {
            setLoading(true);
            const opts = { ...BASE_DELETE_OPTION };
            opts.headers = new Headers();
            opts.headers.append("Authorization", `Bearer ${tokenLocalItemValue}`);
            opts.body = new FormData();
            opts.body.append('itemId', id.toString());
            const response = await fetch(`${BASE_URL}${endpoint}`, opts);
            if (!response.ok || response.body === null) {
                throw new Error(response.statusText ?? 'Erreur lors de la suppression de l\'élément');
            } else {
                const data = await response.json();
                setLoading(false);
                if (onDelete) {
                    onDelete(id);
                }
                setData(data);
            }
        } catch (e: any) {
            setLoading(false);
            setError(e);
        }
    };
    const updateItem = async <U extends Identifiable>(element: U): Promise<U | undefined> => {
        try {
            const updatedItem = element as unknown as T;
            const opts = { ...BASE_PUT_OPTIONS };
            opts.headers = { Authorization: `Bearer ${tokenLocalItemValue}` };
            opts.body = new FormData();
            opts.body.append('item', JSON.stringify(updatedItem));
            const response = await fetch(`${BASE_URL}${endpoint}`, opts);
            if (!response.ok || response.body === null) {
                setError(new Error(response.statusText ?? 'Erreur lors la mise à jour de l\'élément'))
            } else {
                let editedItem: U = await response.json();

                return Promise.resolve(editedItem);
            }
        } catch {
            return Promise.resolve(undefined);
        }
    };
    const updateDataItem = (editedItem: T): void => {
        const index = data.findIndex(item => item.id === editedItem.id);
        if (index !== -1) {
            data[index] = editedItem;
        } else {
            console.warn(`Item with id ${editedItem.id} not found in data`);
        }
    };
    const fetchAllItems = async () => {
        if (!tokenLocalItemValue) return;
        try {
            setLoading(true)
            setData([])
            const opts = { ...BASE_GET_OPTIONS };
            opts.headers = new Headers();
            opts.headers.append('Authorization', `Bearer ${tokenLocalItemValue}`);
            if (siteDependant && !siteChosen) return
                else opts.headers.append("Site", siteChosen?.id.toString() ?? "0");
            if (fabOrderTypeDependant && !fabOrderTypeChosen?.id) return
                else opts.headers.append("Type", fabOrderTypeChosen?.id.toString() ?? "0");
            if (specificIdentifierDependant && !specificIdentifierChosen) return
                else opts.headers.append("SpecificId", specificIdentifierChosen?.toString() ?? "0");

            const response = await fetch(`${BASE_URL}${endpoint}s`, opts);
            if (!response.ok || response.body === null) {
                throw new Error(response.statusText ?? 'Erreur lors de la récupération des éléments');
            }
            const data = await response.json();
            setData(data);
        } catch (error: any) {
            setError(error);
        }
    };
    const refreshItems = async () => {
        setLoading(true);
        fetchAllItems().then(() => setLoading(false));
    };
    useEffect(() => {
        if (data.length === 0) {
            fetchAllItems().then(() => setLoading(false));
        }
    }, [tokenLocalItemValue]);
    useEffect(() => {
        if (siteDependant) {
            fetchAllItems().then(() => setLoading(false));
        }
    }, [siteChosen]);
    useEffect(() => {
        if (fabOrderTypeDependant) {
            fetchAllItems().then(() => setLoading(false));
        }
    }, [fabOrderTypeChosen]);
    useEffect(() => {
        if (specificIdentifierDependant) {
            fetchAllItems().then(() => setLoading(false));
        }
    }, [specificIdentifierChosen]);

    return { data, loading, error, resetApiError, getItemById, addItem, updateItem, deleteItem, refreshItems };
}