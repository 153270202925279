import {FModal} from "../../ui/elements/FModal";
import React, {FC, SyntheticEvent, useState} from "react";
import {IFModal} from "../../ui/interfaces/elements/IFModal";
import {TextInput} from "../../ui/inputs/TextInput";
import {Button} from "../../ui/inputs/Button";
import {useCompaniesApi} from "../../../api/base_apis";
import {Autocomplete, TextField} from "@mui/material";
import {useSitesApi} from "../../../api/base_apis";
import {ISite} from "../../../interfaces/ISite";
import {ICompany} from "../../../interfaces/ICompany";
import {CheckCircleIcon} from "@heroicons/react/24/solid";

export const SiteEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const [label, setLabel] = useState<string>("")
    const [address, setAddress] = useState<string>("")
    const [zipCode, setZipCode] = useState<string>("")
    const [city, setCity] = useState<string>("")
    const [companyId, setCompanyId] = useState<number>(0)
    const [rfidReference, setRfidReference] = useState<string>("")
    const {data: companies} = useCompaniesApi()
    const {addItem: addSite, loading} = useSitesApi()
    const onValidSite = () => {
        const newSite: ISite = {
            id: 0,
            label: label,
            address: address,
            zipCode: zipCode,
            city : city,
            companyId: companyId,
            rfidReference: rfidReference
        }
        // TODO Check inputs
        addSite(newSite).then(r => {
            if (setModalOpen) setModalOpen(false)
        })

    }
    const onCompanyChange = (e: SyntheticEvent<Element, Event>, newValue: ICompany | null) => {
        e.preventDefault();
        setCompanyId(newValue?.id ?? 0)
    }
    return(<FModal className="fab_editor_modal" canClose onClose={onClose} title={"Ajouter un site"}>
        <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
            <TextInput value={label} setterChange={setLabel} label={"Nom du site :"}
                       className={"fab_editor_modal_input"}/>
            <TextInput value={address} setterChange={setAddress} label={"Addresse :"}
                       className={"fab_editor_modal_input"}/>
            <TextInput value={zipCode} setterChange={setZipCode} label={"Code Postal :"}
                       className={"fab_editor_modal_input"}/>
            <TextInput value={city} setterChange={setCity} label={"Ville :"}
                       className={"fab_editor_modal_input"}/>
            <div className="fab_editor_modal_input !justify-around">
                <label className={"w-1/3 text-left border-b"}>Société :</label>
                <Autocomplete
                    id="combo-box-company"
                    options={companies}
                    onChange={onCompanyChange}
                    className={"w-2/3 fab_input !border-0"}
                    renderInput={
                        (params) =>
                            <TextField {...params} label="" variant={'standard'}/>
                    }
                />
            </div>
            <TextInput value={rfidReference} setterChange={setRfidReference} label={"Valeur d'encodage RFID"}
                       className={"fab_editor_modal_input"}/>
        </div>

            <Button onClick={onValidSite} leftIcon={<CheckCircleIcon />} loading={loading} text={"Valider"} className={"mt-2 mb-1 w-1/12"}/>
        </FModal>
    )

}