import {FModal} from "../../ui/elements/FModal";
import React, {FC, useState} from "react";
import {IFModal} from "../../ui/interfaces/elements/IFModal";
import {TextInput} from "../../ui/inputs/TextInput";
import {Button} from "../../ui/inputs/Button";
import {ICompany} from "../../../interfaces/ICompany";
import {useCompaniesApi} from "../../../api/base_apis";
import {CheckCircleIcon} from "@heroicons/react/24/solid";

export const CompanyEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const [label, setLabel] = useState<string>("")
    const [rfidReference, setRfidReference] = useState<string>("")
    const {addItem: addCompany, loading} = useCompaniesApi()
    const onValidCompany = () => {
        const newCompany: ICompany = {
            id: 0,
            label: label,
            Sites: [],
            rfidReference: rfidReference,
            createdAt: new Date(),
            updatedAt: new Date()
        }
        // TODO Check inputs
        addCompany(newCompany).then(() => {
            if (setModalOpen) setModalOpen(false)
        })
    }
    return(<FModal className="fab_editor_modal" canClose onClose={onClose} title={"Ajouter une société"}>
        <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
            <TextInput value={label} setterChange={setLabel} label={"Nom de la société"}
                       className={"fab_editor_modal_input"}/>
            <TextInput value={rfidReference} setterChange={setRfidReference} label={"Valeur d'encodage RFID"}
                       className={"fab_editor_modal_input"}/>
        </div>
            <Button onClick={onValidCompany} leftIcon={<CheckCircleIcon />} text={"Valider"} loading={loading} className={"mt-2 mb-1 w-1/12"}/>
    </FModal>
)

}