import {Button} from "../ui/inputs/Button";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import React, {useEffect, useState} from "react";
import {
    GridColDef,
    GridRowModesModel, GridRowParams,
    ValueOptions
} from "@mui/x-data-grid";
import {FDataGrid} from "../ui/elements/FDataGrid";
import {useRolesApi, useUsersApi} from "../../api/base_apis";
import { UserEditor } from "./editors/UserEditor";
import {FModal} from "../ui/elements/FModal";
import {User} from "../../interfaces/User";
import {PasswordEditor} from "./editors/PasswordEditor";
import {useFabApp} from "../../hooks/useFabApp";

export const UsersPage = () => {
    const {data: users, loading, error,getItemById: getUserById, deleteItem: deleteUser,updateItem: updateUser, refreshItems: refreshUsers, resetApiError} = useUsersApi();
    const {data: fabRoles} = useRolesApi()
    const {user} = useFabApp()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalAddUserOpen, setModalAddUserOpen] = useState<boolean>(false)
    const [modalEditPassword, setModalEditPassword] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<User>()
    const [init, setInit] = useState<boolean>(false)
    const [columns, setColumns] = useState<GridColDef[]>([])
    const onCloseAddUserModal = (e: React.MouseEvent) => {
        setModalAddUserOpen(false)
    }
    const resetError = () => {
        resetApiError()
    }
    const openModalAddUser = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!modalAddUserOpen) setModalAddUserOpen(true);
    }
    const editUserPassword = (userId: number) => {
        console.log("User ? ")
        if (!user) return
        console.log("Ok ")
        if (!modalEditPassword)
        {
            console.log("Ok2 ")
            console.log(userId + '')
            const foundSelectedUser = getUserById(userId)
            console.log("Ok3 ")
            if (foundSelectedUser != undefined) {
                console.log("Ok4 ")
                if (foundSelectedUser.superUser != undefined && foundSelectedUser.superUser) {
                    if (!user.superUser) return
                }
                console.log("Ok 5")
                setSelectedUser(foundSelectedUser)
                setModalEditPassword(true);
            }
        }
    }
    const onCloseEditUserPassword = (e: React.MouseEvent) => {
        setModalEditPassword(false)
        setSelectedUser(undefined)
    }
    useEffect(() => {
        if (fabRoles.length <= 0 ) return;
        if (init) return;
        let singleSelectOpts: ValueOptions[] = []
        fabRoles.map(role => {
            singleSelectOpts.push({label: role.label, value: role.id})
        })
        setColumns([
            { field: 'id', headerName: '#', width: 50 },
            { field: 'fullName', headerName: 'Nom Complet', type: 'string', width: 150, editable:true},
            { field: 'email', headerName: 'E-Mail', type: 'string', width: 150, editable:true},
            { field: 'login', headerName: 'Identifiant WEB', type: 'string', width: 200, editable:true},
            { field: 'mobileLogin', headerName: 'Identifiant MOBILE', type: 'string', width: 150, editable:true},
            { field: 'roleId', headerName: 'Rôle', type:'singleSelect', valueOptions: singleSelectOpts, editable: true, width: 200},
            {
                field: 'edit_password', headerName: '-', type: 'actions', width: 200, cellClassName: 'actions',
                getActions: (params: GridRowParams) => {
                    return [
                        <Button
                            text={"Modifier mot de passe"}
                            onClick={() => {
                                editUserPassword(params.row.id)
                            }}
                        />
                    ]
                }
            }
        ])
        setInit(true)
    }, [fabRoles]);
    useEffect(() => {
        if (!modalAddUserOpen) refreshUsers().then();
    }, [modalAddUserOpen]);
    return (
        <div className="w-full h-full bg-fab_app_container flex flex-col">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">PARAMÈTRAGE DES UTILISATEURS</span>
            </div>
            <div className="w-1/4 p-5 m-auto">
                <Button text={"Ajouter un utilisateur"} leftIcon={<PlusCircleIcon />}
                        onClick={openModalAddUser}/>
            </div>
            {rowModesModel && init &&
                <div className="w-full flex-1">
                    <FDataGrid
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    deleteRow={deleteUser}
                    updateRow={updateUser}
                    rows={users}
                    loading={loading}
                    columns={columns}
                    />
                </div>
            }
            {
                modalAddUserOpen && <UserEditor onClose={onCloseAddUserModal} setModalOpen={setModalAddUserOpen}/>
            }
            {
                modalEditPassword && <PasswordEditor user={selectedUser} onClose={onCloseEditUserPassword} setModalOpen={setModalEditPassword}/>
            }
            { error &&
                <FModal className="bg-white w-2/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl" canClose onClose={resetApiError} title={"Erreur"}>
                    <div className={"w-full flex flex-col mt-4"}>
                        <p className="p-5 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error.message}</p>
                    </div>
                </FModal>
            }
        </div>
    )
}