import React, {useEffect, useState} from "react";
import {Button} from "../ui/inputs/Button";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import {GridColDef, GridRowModesModel, ValueOptions} from '@mui/x-data-grid';
import {useSitesApi} from "../../api/base_apis";
import {FDataGrid} from "../ui/elements/FDataGrid";
import {useCompaniesApi} from "../../api/base_apis";
import {SiteEditor} from "./editors/SiteEditor";
export const SitesPage = () => {
    const {data: sites, loading, error, updateItem: updateSite, deleteItem: deleteSite, refreshItems: refreshSites} = useSitesApi();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const {data: companies} = useCompaniesApi()
    const [init, setInit] = useState<boolean>(false)
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [modalAddSiteOpen, setModalAddSiteOpen] = useState<boolean>(false)
    const openAddSiteModal = () => {
        setModalAddSiteOpen(true)
    }
    useEffect(() => {
        if (init) return;
        if (companies.length <= 0) return;
        let singleSelectOpts: ValueOptions[] = []
        companies.map(company => {
            singleSelectOpts.push({label: company.label, value: company.id})
        })
        setColumns([
            { field: 'id', headerName: '#', width: 50 },
            { field: 'label', headerName: 'Nom', editable: true, width: 100},
            { field: 'companyId', headerName: 'Société', type:'singleSelect', valueOptions: singleSelectOpts, editable: true, width: 100},
            { field: 'address', headerName: 'Addresse',editable: true, width: 200},
            { field: 'zipCode', headerName: 'Code Postal',editable: true, width: 100},
            { field: 'city', headerName: 'Ville',editable: true, width: 170},
            { field: 'rfidReference', headerName: 'Valeur RFID',editable: true, width: 100},
        ])
        setInit(true)
    }, [companies]);

    useEffect(() => {
        if (!modalAddSiteOpen) refreshSites().then(r => r);
    }, [modalAddSiteOpen]);
    return (
        <>
            <div className="w-full h-full bg-fab_app_container flex flex-col">
                <div className="panel_page_entete">
                    <span className="text-2xl m-auto">SITES</span>
                </div>
                <div className="w-1/4 p-5 m-auto">
                    <Button text={"Ajouter un site"} leftIcon={<PlusCircleIcon/>}
                            onClick={openAddSiteModal}/>
                </div>
                {error &&
                    <div
                        className="text-white bg-fab_light_red-400 bg-opacity-75 rounded-md p-2 h-10 w-10/12 flex justify-center">
                        <p className="text-ellipsis overflow-hidden text-center">{error.message}</p>
                    </div>
                }
                {rowModesModel &&
                    <div className="w-full flex-1">
                        <FDataGrid
                            rowModesModel={rowModesModel}
                            setRowModesModel={setRowModesModel}
                            updateRow={updateSite}
                            deleteRow={deleteSite}
                            confirmDeleteText={"La suppression entraînera la perte de tous les ordres du site"}
                            rows={sites}
                            loading={loading}
                            columns={columns}/>
                    </div>
                }
                {init && modalAddSiteOpen &&
                    <SiteEditor onClose={() => setModalAddSiteOpen(false)} setModalOpen={setModalAddSiteOpen}/>
                }

            </div>
        </>
    )
}