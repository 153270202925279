import {Button} from "../ui/inputs/Button";
import {CheckCircleIcon, CpuChipIcon, DevicePhoneMobileIcon} from "@heroicons/react/24/solid";
import React, {useState} from "react";
import {EncodingFormatEditor} from "./editors/EncodingFormatEditor";
import {MobileSimulator} from "./editors/MobileSimulator";
import {FileInput} from "../ui/inputs/FileInput";
import {useCustomerLogoApiSpe} from "../../api/imagesApiSpe";

export const ProcessesPage = () => {
    const [modalCreateEncodingFormat, setModalCreateEncodingFormat] = useState<boolean>(false)
    const [modalMobileSimulation, setModalMobileSimulation] = useState<boolean>(false)
    const [imageFile, setImageFile] = useState<File | null>(null)
    const { sendNewLogo } = useCustomerLogoApiSpe()
    const onCloseModal = (e: React.MouseEvent) => {
        setModalCreateEncodingFormat(false)
        setModalMobileSimulation(false)
    }
    const openModalCreateEncodingFormat = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!modalCreateEncodingFormat) setModalCreateEncodingFormat(true);
    }
    const openModalMobileSimulation = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!modalMobileSimulation) setModalMobileSimulation(true);
    }
    const onValidImageUpload = (e: React.MouseEvent) => {
        e.preventDefault();
        if (imageFile != null) {
            sendNewLogo(imageFile)
        }
    }
    return (
        <div className="w-full h-full bg-fab_app_container">
            <div className="w-full flex text-center bg-fab_congress-blue-800 text-white h-20">
                <span className="text-2xl m-auto">ESPACE DEMO</span>
            </div>
            <div className="w-1/4 p-5 m-auto">
                <Button onClick={openModalCreateEncodingFormat} text={"Ajouter un format d'encodage"}
                        leftIcon={<CpuChipIcon />}/>
            </div>
            {/*<div className="w-1/2 p-5 m-auto">*/}
            {/*    <Button onClick={openModalMobileSimulation} text={"Simulation Mobile"}*/}
            {/*            leftIcon={<DevicePhoneMobileIcon className="h-12 w-10"/>}/>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <FileInput setterChange={setImageFile} label={"Télécharger un nouveau logo"}/>*/}
            {/*    <Button onClick={onValidImageUpload} text={"Valider le nouveau logo"}*/}
            {/*            leftIcon={<CheckCircleIcon className="h-12 w-10"/>}/>*/}
            {/*</div>*/}
            {
                modalCreateEncodingFormat &&
                <EncodingFormatEditor onClose={onCloseModal} setModalOpen={setModalCreateEncodingFormat}/>
            }
            {
                modalMobileSimulation &&
                <MobileSimulator onClose={onCloseModal} setModalOpen={setModalMobileSimulation}/>
            }
        </div>
    )
}