import '../fab_ui.css'
import React, { FC } from 'react'
import {IExtendedNumberInput} from '../interfaces/inputs/IExtendedInput';

export const NumberInput: FC<IExtendedNumberInput> = ({value, defaultValue, className, onChange, label, setterChange, data}) => {
    //const uuid = randomUUID()
    const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault()
        if(setterChange == undefined) return
        setterChange(parseInt(e.currentTarget.value))
    }
    return(
        <div className={className}>
            {label && <label className="w-1/3 text-left">{label}</label>}
            <input
                type='number'
                min="0"
                onChange={onChange ?? onChangeHandler}
                defaultValue={defaultValue}
                value={value}
                data-custom={data}
                className={`w-2/3 fab_input`}
            />
        </div>
    )
}