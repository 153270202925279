import './App.css';
import FabAppProvider from './contexts/FabAppProvider';
import {BrowserRouter, Routes, Route, HashRouter} from 'react-router-dom';
import {AuthenticatedRoute} from './components/layout/AuthenticatedRoute';
import {Dashboard, DashBoardPage} from './components/pages/Dashboard';
import {Login} from './components/pages/Login';
import {NotFound} from './components/pages/404';
import {TagInformationsPage} from "./components/pages/TagInformationsPage";
import {ProcessesPage} from "./components/pages/ProcessPage";
import {CompaniesPage} from "./components/pages/CompaniesPage";
import {SitesPage} from "./components/pages/SitesPage";
import {FabOrderTypePage} from "./components/pages/FabOrderTypePage";
import {RolesPage} from "./components/pages/RolesPage";
import {UsersPage} from "./components/pages/UsersPage";
import {TagsPage} from "./components/pages/TagsPage";
import {SiteMovementsPage} from "./components/pages/SiteMovementsPage";
import {FabOrderMovementsPage} from "./components/pages/FabOrderMovementsPage";
import {FabOrderTypesPage} from "./components/pages/FabOrderTypesPage";
import {EncodingFormatsPage} from "./components/pages/EncodingFormatsPage";
import {ApplicationDevicesPage} from "./components/pages/ApplicationDevicesPage";
function App() {
    // @ts-ignore
    const basename = window.config.REACT_APP_BASENAME ?? '/';
    return (
        <FabAppProvider>
            <div className="flex w-full h-screen">
                <HashRouter basename={basename}>
                    <div className="w-full">
                        <Routes>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/*" element={<NotFound/>}/>
                            <Route path="/login" element={<Login/>}/>
                                <Route path="/dashboard"
                                       element={
                                           <AuthenticatedRoute element={<Dashboard/>}/>
                                       }>
                                    <Route path="" element={<AuthenticatedRoute element={<DashBoardPage/>}/>}/>
                                    <Route path="*"                                 element={<NotFound/>}/>
                                    <Route path="companies"
                                           element={<AuthenticatedRoute admin superUser element={<CompaniesPage/>}/>}/>
                                    <Route path="sites"
                                           element={<AuthenticatedRoute admin superUser element={<SitesPage/>}/>}/>
                                    <Route path="tags"
                                           element={<AuthenticatedRoute element={<TagsPage/>}/>}/>
                                    <Route path="movements"
                                           element={<AuthenticatedRoute element={<SiteMovementsPage/>}/>}/>
                                    <Route path="roles"
                                           element={<AuthenticatedRoute admin superUser element={<RolesPage/>}/>}/>
                                    <Route path="users"
                                           element={<AuthenticatedRoute admin superUser element={<UsersPage/>}/>}/>
                                    <Route path="tags_informations"
                                           element={<AuthenticatedRoute superUser element={<TagInformationsPage/>}/>}/>
                                    <Route path="processes"
                                           element={<AuthenticatedRoute superUser element={<ProcessesPage/>}/>}/>
                                    <Route path="encoding_formats"
                                           element={<AuthenticatedRoute superUser element={<EncodingFormatsPage />}/>}/>
                                    <Route path="fab_order_types/:id"
                                           element={<AuthenticatedRoute element={<FabOrderTypePage />}/>}/>
                                    <Route path="fab_order_types"
                                           element={<AuthenticatedRoute superUser element={<FabOrderTypesPage />}/>}/>
                                    <Route path="fab_order_lines/:type/:id"
                                           element={<AuthenticatedRoute element={<FabOrderMovementsPage />}/>}/>
                                    <Route path="devices"
                                           element={<AuthenticatedRoute element={<ApplicationDevicesPage />}/>}/>
                                </Route>
                        </Routes>
                    </div>
                </HashRouter>
            </div>
        </FabAppProvider>
    );
}

export default App;
