import {NavBar} from "../layout/NavBar";
import {Outlet} from "react-router-dom";
import FabEntete from "../../assets/logo_fabtracer.png";
import React from "react";
import {useDashboardApi} from "../../api/base_apis";
import {
    ArrowsRightLeftIcon,
    BuildingOffice2Icon,
    CalendarDaysIcon,
    MapPinIcon,
    TagIcon
} from "@heroicons/react/24/solid";
import {FDashButton} from "../ui/inputs/FDashButton";


export const DashBoardPage = () => {
    const {data: dashboards} = useDashboardApi();
    return (
        <div className="w-full h-full bg-fab_app_container flex flex-col">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">TABLEAU DE BORD</span>
            </div>
            {
                dashboards.length > 0 && <div className={"w-1/2 h-1/2 m-auto flex flex-col gap-10"}>
                    <div className={"w-full flex justify-center gap-10 m-auto"}>
                        <FDashButton
                            leftIcon={<MapPinIcon
                                        className={"h-24"}/>
                            }
                            route={'companies'}
                            text={dashboards[0].countCompany + " Sociéte(s)"}
                        />
                        <FDashButton
                            leftIcon={<BuildingOffice2Icon
                                className={"h-24"}/>
                            }
                            route={'sites'}
                            text={dashboards[0].countSite + " Sites(s)"}
                        />
                    </div>
                    <div className={"w-full flex gap-10 m-auto justify-center"}>
                        <FDashButton
                            leftIcon={<TagIcon
                                className={"h-24"}/>
                            }
                            route={'tags'}
                            text={dashboards[0].countTag + " Tags"}
                        />
                        <FDashButton
                            leftIcon={<CalendarDaysIcon
                                className={"h-24"}/>
                            }
                            route={'movements'}
                            text={dashboards[0].countMovementsInWeek + " Mouvements cette semaine"}
                        />
                        <FDashButton
                            leftIcon={<ArrowsRightLeftIcon
                                className={"h-24"}/>
                            }
                            route={'movements'}
                            text={dashboards[0].countAllMovements + " Mouvements au total"}
                        />
                    </div>
                </div>
            }
        </div>
    );
};
export const Dashboard = () => {
    return (
        <>
            <div className="w-full h-full flex">
                <NavBar/>
                <div className="w-full flex flex-col">
                    <div className="w-full flex h-16 bg-fab_congress-blue-800">
                        {/*<img alt="Logo RECO" src={LogoReco}/>*/}
                        <img alt="Logo FabTracer" src={FabEntete} className="h-2/4 m-auto"/>
                    </div>
                    <Outlet/>
                </div>

                {/*<NotificationsBar/>*/}
            </div>
        </>
    );
};