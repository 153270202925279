import {FModal} from "../../ui/elements/FModal";
import React, {FC, useEffect, useState} from "react";
import {IFModal} from "../../ui/interfaces/elements/IFModal";
import {TextInput} from "../../ui/inputs/TextInput";
import {Button} from "../../ui/inputs/Button";
import {useFabOrderLineMovements, useFabOrderTypesApi, useFabOrdersApi} from "../../../api/base_apis";
import {FabOrderType} from "../../../interfaces/FabOrderType";
import {DoorSliding} from "@mui/icons-material";
import {useFabApp} from "../../../hooks/useFabApp";
import {FabOrder, FabOrderLineMovement, FabOrderLineMovementInfo} from "../../../interfaces/FabOrder";
import {NumberInput} from "../../ui/inputs/NumberInput";
import {FCheckBox} from "../../ui/inputs/FCheckBox";
import {useTagsApiSpe} from "../../../api/tagsApi";

export const MobileSimulator: FC<IFModal> = ({onClose}) => {
    const [simulatedTag, setSimulatedTag] = useState<string>("")
    const [encodedValue, setEncodedValue] = useState<string>("")
    const {data: fabOrderTypes} = useFabOrderTypesApi()
    const {data: orders} = useFabOrdersApi()
    const {addItem: createLineMovement} = useFabOrderLineMovements()
    const {siteChosen, setFabOrderTypeChosen} = useFabApp()
    const {checkAndAskEncodingForTag, encodeEPC} = useTagsApiSpe()
    const [selectedFabOrderType, setSelectedFabOrderType] = useState<FabOrderType | null>(null)
    const [orderSelected, setOrderSelected] = useState<FabOrder | null>(null)
    const [step, setStep] = useState<number>(0)
    const [tagInput, setTagInput] = useState<string>("")
    const [userInputs, setUserInputs] = useState<FabOrderLineMovementInfo[]>([])
    useEffect(() => {
        setStep(0)
        let newUserInputsTab: FabOrderLineMovementInfo[] = []
        selectedFabOrderType?.Infos?.map(info => {
            console.log(info)
            let newLineMovementInfo: FabOrderLineMovementInfo = {
                id: 0,
                tagInfoTypeId: info.tagInfoTypeId,
                tagInfoTypeValueId: info.TagInfo?.Values.length ? info.TagInfo?.Values[0].id : 0,
                intValue: 0,
                stringValue: "",
            }
            newUserInputsTab.push(newLineMovementInfo)
        })
        if (selectedFabOrderType?.useOrder) {
            setFabOrderTypeChosen(selectedFabOrderType)
            // getOrdersForType(selectedFabOrderType.id).then(
            //     (data) => {
            //         setOrders(data)
            //     }
            // ).catch(error => console.log(error))
        }
        setUserInputs(newUserInputsTab)
    }, [selectedFabOrderType]);
    useEffect(() => {
        if (simulatedTag === '') return
        checkAndAskEncodingForTag(orderSelected!.id, simulatedTag).then(res => {
            setEncodedValue(res)
        })
    }, [simulatedTag]);
    const onSelectUserInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        let tagInfoTypeId = verifyInfosForInput(e.target)
        let value = e.target.value
        if (tagInfoTypeId > -1) {
                let copyUserInputs = userInputs
                copyUserInputs.map((userInput, index) => {
                    if (userInput.tagInfoTypeId === tagInfoTypeId) {
                        copyUserInputs[index].tagInfoTypeValueId = parseInt(value)
                        return
                    }
                })
                setUserInputs(copyUserInputs)
        }
        return
    }
    const verifyInfosForInput = (target: HTMLInputElement | HTMLSelectElement): number => {
        let tagInfoIdString = target.getAttribute("data-custom")
        if (!tagInfoIdString) return -1
        let tagInfoTypeId = parseInt(tagInfoIdString)
        let infoInOrderType = selectedFabOrderType?.Infos.find(i => i.id === tagInfoTypeId)
        if (infoInOrderType) {
            if (infoInOrderType.TagInfo?.Values && infoInOrderType.TagInfo.Values.length > 0) {
                let valueId = parseInt(target.value)
                console.log("CHECK VALUE ID"+valueId)
                let valueInTagInfo = infoInOrderType.TagInfo.Values.find(ti => ti.id === valueId)
                if (valueInTagInfo) {
                    return tagInfoTypeId
                }
            } else {
                return tagInfoTypeId
            }
        }
        return -1
    }
    const onChangeUserTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        let tagInfoTypeId = verifyInfosForInput(e.target)
        let value = e.target.value
        if (tagInfoTypeId > -1) {
            let copyUserInputs = userInputs
            copyUserInputs.map((userInput, index) => {
                if (userInput.tagInfoTypeId === tagInfoTypeId) {
                    copyUserInputs[index].stringValue = value
                    return
                }
            })
            setUserInputs(copyUserInputs)
        }
        return
    }
    const onChangeUserBooleanInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        let tagInfoTypeId = verifyInfosForInput(e.target)
        if (tagInfoTypeId > -1) {
            let copyUserInputs = userInputs
            copyUserInputs.map((userInput, index) => {
                if (userInput.tagInfoTypeId === tagInfoTypeId) {
                    copyUserInputs[index].intValue = userInputs[index].intValue === 0 ? 1 : 0
                    return
                }
            })
            setUserInputs(copyUserInputs)
        }
        return
    }
    const onChangeUserNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        let tagInfoTypeId = verifyInfosForInput(e.target)
        if (tagInfoTypeId > -1) {
            let copyUserInputs = userInputs
            copyUserInputs.map((userInput, index) => {
                if (userInput.tagInfoTypeId === tagInfoTypeId) {
                    copyUserInputs[index].intValue = parseInt(e.target.value)
                    return
                }
            })
            setUserInputs(copyUserInputs)
        }
        return
    }
    const handleChooseOrder = (e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault()
        const orderIdString = e.currentTarget.getAttribute('data-orderid')
        if (orderIdString == null) return
        let orderId = parseInt(orderIdString)
        let order = orders.filter(o => o.id == orderId)
        if (order != null) {
            setOrderSelected(order[0])
            setStep(prevState => prevState + 1)
        }
    }
    const endMovement = () => {
        //let infosEncode = JSON.stringify()

        //         opts.body.append('siteId', siteChosen!.id.toString())
//         opts.body.append('companyId', siteChosen!.companyId.toString())
//         opts.body.append('infosEncode', infosEncode )
//         opts.body.append('fabOrderId', fabOrderId.toString())
//         opts.body.append('fabOrderTypeId', fabOrderTypeId.toString())
//         opts.body.append('tagIdentifier', tagIdentifier)

        const newLineMovement: FabOrderLineMovement = {
            id: 0,
            Infos: userInputs,
            companyId: siteChosen!.companyId,
            siteId: siteChosen!.id,
            tagIdentifier: tagInput,
            userId: 0,
        }
        createLineMovement(newLineMovement).then (res => {
            setStep(0)
        })
    }
    const simuTag = () => {
        const simulation =  Math.floor(( Math.random() * (9999999999 - 1000000000) + 1000000000 )) + 'A'
        setSimulatedTag(simulation)
    }
    const validEncode = () => {
        encodeEPC(simulatedTag, encodedValue, orderSelected?.id ?? 0).then( res => {
            setStep(1)
            setEncodedValue("")
        })
    }
    return(<FModal className="bg-white w-3/12 h-5/6 rounded-md m-auto justify-center text-center shadow-2xl" canClose onClose={onClose}>
        {!selectedFabOrderType && fabOrderTypes && fabOrderTypes.length > 0 &&
            fabOrderTypes.map(type => {
            return((
                <Button onClick={() => setSelectedFabOrderType(type)
                } text={type.label} />
            ))
        })}
        {
            selectedFabOrderType && !selectedFabOrderType.useOrder && <>
                <p className="text-xl flex m-auto">{selectedFabOrderType.label} <Button soloIcon={<DoorSliding/>}
                                                                                        onClick={() => setSelectedFabOrderType(null)}/>
                </p>
                {
                    step == 0 &&
                        <>
                            {selectedFabOrderType.useTag ?
                                <p>Saisir un tag EXISTANT</p>
                                :
                                <p>Saisir un NOUVEAU tag</p>
                            }
                            <TextInput value={tagInput} setterChange={setTagInput}/>
                            <Button text={"Valider"} onClick={() => setStep( cstep => cstep + 1)} />
                        </>
                }
                {
                    step == 1 &&
                        <>
                            {
                                selectedFabOrderType.Infos.map(info => {
                                    return <>
                                        { info.isUserInput && <div className={"w-full flex"}>
                                            <p>{info.TagInfo?.label}</p>
                                            {

                                                    info.TagInfo?.Values && info.TagInfo?.Values.length > 0 ?
                                                    <select data-custom={info.id} onChange={onSelectUserInput}>
                                                        {info.TagInfo?.Values.map(value => (
                                                            <option value={value.id}>[{value.id}]{value.label}</option>
                                                        ))}
                                                    </select>
                                                    : <div data-taginfoid={info.id}>
                                                        {
                                                            info.TagInfo?.datatype === "TEXT" &&
                                                            <TextInput data={info.id} defaultValue={""} onChange={onChangeUserTextInput}/>
                                                            || info.TagInfo?.datatype === "BOOLEAN" &&
                                                            <FCheckBox data={info.id} onChange={onChangeUserBooleanInput}/>
                                                            || info.TagInfo?.datatype === "NUMBER" &&
                                                            <NumberInput data={info.id} defaultValue={0} label={""}
                                                                         onChange={onChangeUserNumberInput}/>
                                                            || info.TagInfo?.datatype === "DATE" &&
                                                            <TextInput data={info.id} value={tagInput} data-taginfoid={info.id}
                                                                       onChange={onChangeUserTextInput}/>
                                                        }
                                                    </div>
                                            }
                                        </div>
                                        }
                                    </>
                                })
                            }
                            {
                                selectedFabOrderType.useEncoding ? <Button text={"Encoder"} onClick={() => setStep( cstep => cstep + 1)} /> : <Button text={"Valider"} onClick={endMovement} />
                            }
                        </>
                }
                {
                    step == 2 &&
                        <>
                            <p>Choisir un Ordre</p>
                            <div className="w-full h-96">

                            </div>
                        </>
                }
            </>
            }
            { selectedFabOrderType && selectedFabOrderType.useOrder && <>
                {
                    step == 0 && <>
                        <ul className={"list-none"}>
                            {orders.map((order: FabOrder) => (<li
                                className={"border w-full text-gray-500 hover:cursor-pointer hover:text-black"}
                                data-orderid={order.id}
                                onClick={handleChooseOrder}>
                                {order.internalOrderId}
                            </li>))}
                        </ul>
                    </>
                    || step == 1 && orderSelected != null && orderSelected.Lines.length > 0 && <>
                        <p className={"p-1 m-2 bg-gray-200"}>Société : {orderSelected?.Site?.Company?.label}</p>
                        <p className={"p-1 m-2 bg-gray-200"}>Site : {orderSelected?.Site?.label}</p>
                        <p className={"p-1 m-2 bg-gray-200"}>Lot : {orderSelected.Lines[0].batch}</p>
                        {
                            orderSelected.Infos.length > 0 &&
                            orderSelected.Infos.map((info) => {
                                return (
                                    <p className={"p-1 m-2 bg-gray-200"}>{info?.InfoType?.label} - {info?.InfoValue?.label}</p>
                                )
                            })
                        }
                        <p className={"p-1 m-2 bg-gray-200"}> Quantité à encoder : {orderSelected.Lines[0].quantity - orderSelected.Lines[0].quantityDone }</p>
                        <Button text={"Démarrer encodage"} onClick={() => setStep(currStep => currStep + 1)} />
                    </>
                    || step == 2  && orderSelected != null && orderSelected.Lines.length > 0 &&
                    <>
                        <Button text={"Simuler SCAN TAG"} onClick={simuTag} />
                        <p>Tag Saisie : {simulatedTag}</p>
                        {
                            encodedValue != "" &&
                                <>
                                    {encodedValue}
                                    <Button text={"Valider encodage"} onClick={validEncode} />
                                </>

                        }
                    </>
                }
            </>
            }
    </FModal>)

}