import { useState, useEffect } from 'react';
import useLocalStorage from "../hooks/useLocalStorage";
import {BASE_GET_OPTIONS, BASE_URL, BASE_POST_OPTIONS} from "./static_vars";
import {useFabApp} from "../hooks/useFabApp";
import {Tag} from "../interfaces/Tag";

export function useTagsApiSpe() {
    const [tags, setTags] = useState<Tag[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const [tokenLocalItemValue] = useLocalStorage("fabToken", "");
    const { siteChosen } = useFabApp()
    const fetchAllTags = async () => {
        if (!tokenLocalItemValue) return
        try {
            const opts = BASE_GET_OPTIONS;
            opts.headers = new Headers()
            opts.headers.append('Authorization', `Bearer ${tokenLocalItemValue}`)
            opts.headers.append('Site', siteChosen!.id.toString())
            const response = await fetch(BASE_URL + 'web/tags', opts);
            if (!response.ok || response.body === null) {
                throw new Error('Erreur lors de la récupération des tags');
            }
            const data = await response.json();
            console.log(data)
            setTags(data);
        } catch (error: any) {
            setError(error);
        }
    };
    const getTagWithValues = async (tagId: number) => {
        if (!tokenLocalItemValue) return
        try {
            const opts = BASE_GET_OPTIONS;
            opts.headers = new Headers()
            opts.headers.append('Authorization', `Bearer ${tokenLocalItemValue}`)
            opts.headers.append('Site', siteChosen!.id.toString())
            opts.headers.append('TagId', tagId.toString())
            const response = await fetch(BASE_URL + 'web/tag', opts);
            if (!response.ok || response.body === null) {
                throw new Error('Erreur lors de la récupération du tag');
            }
            return await response.json();
        } catch (error: any) {
            setError(error);
        }
    };
    const refreshTags = async() => {
        setLoading(true)
        fetchAllTags().then(() => setLoading(false));
    }
    const checkAndAskEncodingForTag = async(orderId: number, epcTag: string ) => {
        if (!tokenLocalItemValue) return
        try {
            const opts = BASE_POST_OPTIONS;
            opts.headers = new Headers()
            opts.headers.append('Authorization', `Bearer ${tokenLocalItemValue}`)
            opts.headers.append('Site', siteChosen!.id.toString())

            opts.body = new FormData()
            opts.body.append('orderId', orderId.toString())
            opts.body.append('epcTag', epcTag)
            const response = await fetch(BASE_URL + 'web/tag/check_and_return_encoding', opts);
            if (!response.ok || response.body === null) {
                throw new Error('Erreur lors de la récupération du tag');
            }
            return await response.json();
        } catch (error: any) {
            console.log("ERROR" + error.message)
            setError(error);
        }
    }
    const encodeEPC = async (epcOrig: string, epcValue: string, idFabOrder: number) => {
        if (!epcValue || !epcOrig) return
        try {
            const opts = BASE_POST_OPTIONS;
            opts.headers = new Headers()
            opts.headers.append('Authorization', `Bearer ${tokenLocalItemValue}`)
            opts.headers.append('Site', siteChosen!.id.toString())

            opts.body = new FormData()
            opts.body.append('epcOrig', epcOrig.toString())
            opts.body.append('newEpcValue', epcValue)
            opts.body.append('idFabOrder', idFabOrder.toString())
            const response = await fetch(BASE_URL + 'web/tag/valid_encoding', opts);
            if (!response.ok || response.body === null) {
                throw new Error("Erreur lors de l'encodage du tag");
            }
            return await response.json();
        } catch {
            setError(error)
        }
    }
    useEffect(() => {
        if (tags.length === 0) {
            fetchAllTags().then(() => setLoading(false));
        }
    }, [tokenLocalItemValue]);
    return { tags, loading, error, refreshTags, getTagWithValues, checkAndAskEncodingForTag, encodeEPC};
}