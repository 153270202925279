import {useApi} from "./_useApi";
import {FabOrderType} from "../interfaces/FabOrderType";
import {Role} from "../interfaces/Role";
import {User} from "../interfaces/User";
import {FabOrder, FabOrderLine, FabOrderLineMovement} from "../interfaces/FabOrder";
import {ISite} from "../interfaces/ISite";
import {ICompany} from "../interfaces/ICompany";
import {EncodingFormat} from "../interfaces/EncodingFormat";
import {DashboardInfos} from "../interfaces/DashboardInfos";
import {Tag} from "../interfaces/Tag";
import {ApplicationDevice} from "../interfaces/ApplicationDevice";
export function useCompaniesApi() {
    return useApi<ICompany>( {
        endpoint: 'web/companie',
    })
}
export function useSitesApi() {
    return useApi<ISite>( {
        endpoint: 'web/site',
    })
}
export function useTagsApi() {
    return useApi<Tag>({
        endpoint: 'web/tag',
    })
}
export function useFabOrderTypesApi() {
    return useApi<FabOrderType>({
        endpoint: 'web/fab_order_type',
    })
}
export function useEncodingFormatsApi() {
    return useApi<EncodingFormat>({
        endpoint: 'web/encoding_format',
    })
}
export function useFabOrdersApi() {
    return useApi<FabOrder>({
        endpoint: 'web/fab_order',
        siteDependant: true,
        fabOrderTypeDependant: true
    })
}
export function useFabOrderLines() {
    return useApi<FabOrderLine>({
        endpoint: 'web/fab_order_line',
        specificIdentifierDependant: true,
    })
}
export function useFabOrderLineMovements() {
    return useApi<FabOrderLineMovement>({
        endpoint: 'web/fab_order_line_movement',
        specificIdentifierDependant: true,
    })
}
export function useSiteMovements() {
    return useApi<FabOrderLineMovement>({
        endpoint: 'web/site/movement',
        siteDependant: true
    })
}
export function useRolesApi() {
    return useApi<Role>({
        endpoint: 'web/role',
    })
}
export function useUsersApi() {
    return useApi<User>({
        endpoint: 'web/user',
    })
}
export function useDashboardApi() {
    return useApi<DashboardInfos>({
        endpoint:'web/dashboard'
    })
}
export function useApplicationDevicesApi() {
    return useApi<ApplicationDevice>({
        endpoint: 'web/application_device'
    })
}