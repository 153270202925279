import {FC} from "react";
import {IFModal} from "../interfaces/elements/IFModal";
import {XCircleIcon} from "@heroicons/react/24/solid";

export const FModal: FC<IFModal> = ({children, className, title, canClose, onClose}) => {
    return(
        <div className="modal_container">
            <div className={className}>
                <div className="text-2xl h-14 m-auto w-full bg-fab_congress-blue-600 text-white text-center flex align-middle">
                    <div className="flex-1 h-full"><p className="mt-3.5">{title}</p></div>
                    {canClose ?
                        <button onClick={onClose}><XCircleIcon
                            className={"h-14 w-14 p-2 hover:fill-red-500 cursor-pointer"}/></button>
                        :
                        <></>
                    }
                </div>
                {children}
            </div>
        </div>)
}