import React, {useEffect, useState} from "react";
import {Button} from "../ui/inputs/Button";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import {
    GridRowModesModel,
} from '@mui/x-data-grid';
import {useCompaniesApi} from "../../api/base_apis";
import {CompanyEditor} from "./editors/CompanyEditor";
import {FDataGrid} from "../ui/elements/FDataGrid";
export const CompaniesPage = () => {
    const {data: companies, loading, error,updateItem: updateCompany,deleteItem: deleteCompany,refreshItems: refreshCompanies} = useCompaniesApi();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const columns: any[] = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'label', headerName: 'Nom', type: 'string', width: 330, editable:true},
        { field: 'rfidReference', headerName: 'Valeur RFID', type: 'string', width: 440, editable:true},
    ]
    const onCloseModal = () => {
        setModalOpen(false)
    }
    const openModal = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!modalOpen) setModalOpen(true);
    }
    useEffect(() => {
        if (!modalOpen) refreshCompanies().then();
    }, [modalOpen]);
    return (
        <>
            <div className="w-full h-full bg-fab_app_container flex flex-col">
                <div className="panel_page_entete">
                    <span className="text-2xl m-auto">SOCIÉTÉ</span>
                </div>
                <div className="w-1/4 p-5 m-auto">
                    <Button text={"Ajouter une société"} leftIcon={<PlusCircleIcon/>}
                            onClick={openModal}/>
                </div>

                {error &&
                    <div
                        className="text-white bg-fab_light_red-400 bg-opacity-75 rounded-md p-2 h-10 w-10/12 flex justify-center">
                        <p className="text-ellipsis overflow-hidden text-center">{error.message}</p>
                    </div>
                }
                {rowModesModel &&
                    <div className="w-full flex-1">
                        <FDataGrid
                            rowModesModel={rowModesModel}
                            setRowModesModel={setRowModesModel}
                            updateRow={updateCompany}
                            deleteRow={deleteCompany}
                            confirmDeleteText={"La suppression entraînera la perte de tous les sites et de leurs ordres"}
                            rows={companies}
                            loading={loading}
                            columns={columns}
                        />
                    </div>
                }
                {
                    modalOpen && <CompanyEditor onClose={onCloseModal} setModalOpen={setModalOpen}/>
                }
            </div>
        </>
    )
}