import React, {FC, SyntheticEvent, useState} from "react";
import {FModal} from "../../ui/elements/FModal";
import {TextInput} from "../../ui/inputs/TextInput";
import {FCheckBox} from "../../ui/inputs/FCheckBox";
import {Button} from "../../ui/inputs/Button";
import {ArrowLeftIcon, ArrowRightIcon, CheckCircleIcon, PlusCircleIcon} from "@heroicons/react/24/solid";
import {TagInfoType} from "../../../interfaces/TagInfoType";
import {Autocomplete, TextField} from "@mui/material";
import {useTagInfoTypesApi} from "../../../api/tagInfoTypesApi";
import {FabOrderType, FabOrderTypeRole} from "../../../interfaces/FabOrderType";
import {IFModal} from "../../ui/interfaces/elements/IFModal";
import {Role} from "../../../interfaces/Role";
import {useEncodingFormatsApi} from "../../../api/base_apis";
import {EncodingFormat} from "../../../interfaces/EncodingFormat";
import {useRolesApi, useFabOrderTypesApi} from "../../../api/base_apis";
import {iconsForFabOrderType} from "../../data/iconsForFabOrderType";

export const FabOrderTypeEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {data: roles} = useRolesApi();
    const {tagInfoTypes} = useTagInfoTypesApi();
    const {data: encodingFormats} = useEncodingFormatsApi();
    const {addItem: addFabOrderType, loading} = useFabOrderTypesApi();
    const [step, setStep] = useState<number>(0);
    const [selectRole, setSelectedRole] = useState<Role>();
    const [fabOrderType, setFabOrderType] = useState<FabOrderType>({
        Roles: [],
        checkSite: false,
        checkCompany: false,
        checkTeam: false,
        checkUser: false,
        useTag: false,
        useBatch: false,
        useOrder: false,
        useEncoding: false,
        icon: "ic_app_settings_alt",
        encodingFormatId: 0,
        Infos: [],
        createdAt: undefined,
        id: 0,
        label: "",
        updatedAt: undefined
    });
    const [selectedIconIndex, setSelectedIconIndex] = useState(0);

    const showIconList = () => {
        const elt = document.getElementById("icons_select")
        if (!elt) return
        elt.classList.toggle("show-flex")
    }
    const prevStep = () => {
        setStep(current => current - 1);
    };
    const nextStep = () => {
        setStep(current => current + 1);
    };
    const onValidAddFabOrderType = () => {
        addFabOrderType(fabOrderType).then(() => {
            if (setModalOpen) setModalOpen(false);
        });
    };
    const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setFabOrderType(prevState => ({
                ...prevState,
                label: e.target.value
            }
        ));
    };
    const onIconChange = (index: number) => {
        setSelectedIconIndex(index)
        const iconInfos = iconsForFabOrderType.at(index)
        if (!iconInfos) return
        setFabOrderType (prevState => ({
            ...prevState,
            icon: iconInfos.label
        }))
    }
    const addInformation = () => {
        setFabOrderType(prevState => ({
            ...prevState,
            Infos: [
                ...prevState.Infos,
                {
                    label: "",
                    id: 0,
                    order: 0,
                    tagInfoTypeId: -1
                }
            ]
        }));
    };
    const activeCheckElement = (e: React.ChangeEvent<HTMLInputElement>) => {
        // TODO A Refaire c'est pas beau
        let subject = e.currentTarget.getAttribute('data-custom');
        let newType = fabOrderType;
        switch (subject) {
            case "company":
                newType.checkCompany = !newType.checkCompany;
                break;
            case "site":
                newType.checkSite = !newType.checkSite;
                break;
            case "team":
                newType.checkTeam = !newType.checkTeam;
                break;
            case "user":
                newType.checkUser = !newType.checkUser;
                break;
            case "tag":
                newType.useTag = !newType.useTag;
                break;
            case "faborder":
                setFabOrderType(prevState => ({
                    ...prevState,
                    useOrder: !prevState.useOrder
                }));
                break;
            case "batch":
                setFabOrderType(prevState => ({
                    ...prevState,
                    useBatch: !prevState.useBatch
                }));
                break;
            case "encoding":
                setFabOrderType(prevState => ({
                        ...prevState,
                        useEncoding: !newType.useEncoding
                    }
                ));
                break;
        }

    };
    const onInformationInputTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        let isInput = e.currentTarget.selectedIndex != 0;
        let indexString = e.currentTarget.getAttribute('data-index');
        if (!indexString) return;
        let indexInt = parseInt((indexString));
        if (indexInt < 0) return;
        setFabOrderType(prevState => {
            const updatedInfos = [...prevState.Infos];
            updatedInfos[indexInt] = {
                ...updatedInfos[indexInt],
                isUserInput: isInput
            };
            return {
                ...prevState,
                Infos: updatedInfos
            };
        });
    };
    const onInformationChange = (e: SyntheticEvent<Element, Event>, newValue: TagInfoType | null) => {
        e.preventDefault();
        let id = e.currentTarget.id;
        let separatorIndex = id.indexOf('-');
        let indexString = id.substring(0, separatorIndex);
        if (!indexString) return;
        let indexInt = parseInt((indexString));
        if (indexInt < 0) return;
        setFabOrderType(prevState => {
            const updatedInfos = [...prevState.Infos];
            updatedInfos[indexInt] = {
                ...updatedInfos[indexInt],
                tagInfoTypeId: newValue ? newValue!.id : 0
            };
            return {
                ...prevState,
                Infos: updatedInfos
            };
        });
    };
    const onEncodingFormatChange = (e: SyntheticEvent<Element, Event>, newValue: EncodingFormat | null) => {
        if (newValue) {
            setFabOrderType(prevState => ({
                ...prevState,
                encodingFormatId: newValue.id
            }));
        }
    };
    const onRoleChange = (e: SyntheticEvent<Element, Event>, newValue: Role | null) => {
        if (newValue) setSelectedRole(newValue);
    };
    const addRole = () => {
        if (!selectRole) return;
        let roleIndex = fabOrderType.Roles.findIndex(role => role.Role.id == selectRole.id);
        if (roleIndex < 0) {
            setFabOrderType(prevState => ({
                ...prevState,
                Roles: [
                    ...prevState.Roles,
                    {
                        id: 0,
                        accessMobile: false,
                        accessWeb: false,
                        Role: selectRole
                    }
                ]
            }));
        }
    };
    const updateRoleWebAccess = (e: React.ChangeEvent<HTMLInputElement>) => {
        // TODO A Refaire c'est pas beau
        let roleId = e.currentTarget.getAttribute('data-custom');
        console.log(roleId);
        if (!roleId) return;
        let roleIntId = parseInt(roleId);
        if (roleIntId < 0) return;
        let updatedRoles: FabOrderTypeRole[] = [];
        fabOrderType.Roles.map(role => {
            let newRole = role;
            if (newRole.Role.id === roleIntId) {
                newRole.accessWeb = !role.accessWeb;
                console.log(newRole);
            }
            updatedRoles.push(newRole);
        });
        setFabOrderType(prevState => (
            {
                ...prevState,
                Roles: updatedRoles
            }
        ));
    };
    const updateRoleMobileAccess = (e: React.ChangeEvent<HTMLInputElement>) => {
        // TODO A Refaire c'est pas beau
        let roleId = e.currentTarget.getAttribute('data-custom');
        if (!roleId) return;
        let roleIntId = parseInt(roleId);
        if (roleIntId < 0) return;
        let updatedRoles: FabOrderTypeRole[] = [];
        fabOrderType.Roles.map(role => {
            let newRole = role;
            if (newRole.Role.id === roleIntId) {
                newRole.accessMobile = !role.accessMobile;
            }
            updatedRoles.push(newRole);
        });
        setFabOrderType(prevState => (
            {
                ...prevState,
                Roles: updatedRoles
            }
        ));
    };
    return (
        (<FModal className="fab_editor_modal" canClose onClose={onClose}
                 title={"Création d'un type d'ordre de fabrication"}>
                <>
                    <div className={`w-[300%] flex steps-container justify-between step-${step}`}>
                        <div className="w-[33%] flex flex-col h-2/3">
                            <div className="w-full flex">
                                <TextInput value={fabOrderType.label} onChange={onLabelChange}
                                           label={"Nom de la procédure :"}
                                           className={"fab_editor_modal_input !mb-5 !mt-5"}/>
                                <div className="w-1/6 text-left m-auto border-b">Icone :</div>
                                <div className="fab_custom-select m-auto !ml-0 w-1/12" onClick={showIconList}>
                                    <div className="fab_select-selected w-12 m-auto border-b-2 border-transparent hover:border-b-fab_mint_green-200">
                                        {iconsForFabOrderType.at(selectedIconIndex)!.icon}
                                    </div>
                                    <div className="fab_select-items max-h-32 w-40 flex flex-wrap mr-48 justify-center" id={"icons_select"}>
                                        {
                                            iconsForFabOrderType.map((item, index) => {
                                                return <div className="hover:!bg-fab_congress-blue-400 m-auto"
                                                            onClick={() => onIconChange(index)}>{item.icon}</div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <h1>Lors de son execution, la procédure doit vérifier : </h1>
                            <div className="w-2/3 flex flex-col gap-2 m-auto mb-2">
                                <FCheckBox onChange={activeCheckElement} data={"company"}
                                           className={"border border-fab_congress-blue-950"}
                                           label={<span>
                                <b>La société </b>
                                ( La société du TAG devras être la même sur que le dernier mouvement, ou renseignée depuis l'utilisateur en cas de création)
                            </span>}/>
                                <FCheckBox onChange={activeCheckElement} data={"site"}
                                           className={"border border-fab_congress-blue-950"}
                                           label={<span>
                                <b>Le site </b>
                                ( Le site du TAG devras être le même sur que le dernier mouvement, ou renseigné depuis l'utilisateur en cas de création)
                            </span>}/>
                                {/*<FCheckBox onChange={activeCheckElement} data={"team"} className={"border border-fab_congress-blue-950"}*/}
                                {/*           label={<span>*/}
                                {/*    <b>L'équipe </b>*/}
                                {/*    ( L'équipe du TAG devras être la même sur que le dernier mouvement, ou renseignée depuis l'utilisateur en cas de création)*/}
                                {/*</span>}/>*/}
                                <FCheckBox onChange={activeCheckElement} data={"user"}
                                           className={"border border-fab_congress-blue-950"}
                                           label={<span>
                                <b>L'utilisateur </b>
                                ( L'utilisateur du TAG devras être la même sur que le dernier mouvement, ou renseigné depuis l'utilisateur en cas de création)
                            </span>}/>
                                <FCheckBox onChange={activeCheckElement} data={"tag"}
                                           className={"border border-fab_congress-blue-950"}
                                           label={<span>
                                <b>L'existence du TAG </b>
                                ( Si oui alors le tag devras exister sinon il sera créer en base de données)
                            </span>}/>
                                <FCheckBox onChange={activeCheckElement} data={"faborder"}
                                           className={"border border-fab_congress-blue-950"}
                                           label={<span>
                                <b>Type de procédure par ordre</b>
                                ( Si oui alors un ordre devras être saisie pour pouvoir éxecuter cette procédure, sinon la procédure sera sous la forme d'un ordre unique. Un order permet de fixer certaines valeurs)
                            </span>}/>
                                {
                                    fabOrderType && fabOrderType.useOrder ?
                                        <FCheckBox onChange={activeCheckElement} data={"batch"}
                                                   className={"border border-fab_congress-blue-950"}
                                                   label={<span>
                                                <b>Type de procédure par lot</b>
                                                ( Si oui alors un ordre devras être saisie pour pouvoir éxecuter cette procédure, sinon la procédure sera sous la forme d'un ordre unique. Un order permet de fixer certaines valeurs)
                                                </span>}
                                        /> : <></>
                                }
                                <FCheckBox onChange={activeCheckElement} data={"encoding"}
                                           className={"border border-fab_congress-blue-950"}
                                           label={<span>
                                <b>Se termine par un encodage</b>
                                    <Autocomplete
                                        options={encodingFormats}
                                        className="fab_input h-10 p-1 w-full"
                                        onChange={onEncodingFormatChange}
                                        renderInput={(params) => <TextField {...params} label=""
                                                                            variant={'standard'}/>}/>
                            </span>}/>

                                {/*<FCheckBox className={"border border-fab_congress-blue-950 w-full"}*/}
                                {/*           onVoidChange={() => setUseArticleType(!useArticleType)}*/}
                                {/*           label={*/}
                                {/*                <div className={"flex w-96 justify-start gap-2"}>*/}
                                {/*                   <b className="mt-auto mb-auto">Le type d'article</b>*/}
                                {/*                   <Autocomplete*/}
                                {/*                       disabled={!useArticleType}*/}
                                {/*                       id="combo-box-demo"*/}
                                {/*                       options={items}*/}
                                {/*                       className="fab_input h-10 p-1 w-1/2"*/}
                                {/*                       renderInput={(params) => <TextField {...params} label=""*/}
                                {/*                                                           variant={'standard'}/>}/>*/}
                                {/*                </div>*/}
                                {/*            }/>*/}
                                {/*<FCheckBox className={"border border-fab_congress-blue-950"}*/}
                                {/*           onVoidChange={() => setUseArticle(!useArticle)}*/}
                                {/*           label={<div className={"flex w-96 justify-start gap-2"}>*/}
                                {/*               <b className="mt-auto mb-auto">L'article</b>*/}
                                {/*               <Autocomplete*/}
                                {/*                   disabled={!useArticle}*/}
                                {/*                   id="combo-box-demo"*/}
                                {/*                   options={items}*/}
                                {/*                   className="fab_input h-10 p-1 w-1/2"*/}
                                {/*                   renderInput={(params) => <TextField {...params} label=""*/}
                                {/*                                                       variant={'standard'}/>}/>*/}
                                {/*           </div>}/>*/}
                            </div>
                        </div>
                        <div className="w-[33%] h-2/3">
                            <h1 className="text-xl">Liste des informations renseignée part la procédure : </h1>
                            <p className="bg-fuchsia-100 rounded">
                                <b>Valeur forcée </b> : Depuis l'ordre ou depuis l'utilisateur, le lieu de connexion, la
                                valeur par défaut si sans ordre
                                / <b>Valeur utilisateur </b> : Depuis l'utilisateur ou le lieu de connexion
                                / <b>Valeur saisie </b> : Depuis une liste ou saisie suivant le type de l'information
                            </p>
                            <div className="w-full p-1">
                                <Button onClick={addInformation}
                                        leftIcon={<PlusCircleIcon className="h-12 w-12 p-2"/>}
                                        text="Ajouter une information" className="mb-3 mt-3"
                                />
                                {fabOrderType && fabOrderType.Infos && fabOrderType.Infos.length > 0 &&
                                    fabOrderType.Infos.map((info, index) => (
                                        <div className={"border-b-2 pb-3 mt-3"}>
                                            <div className="w-full flex gap-2" key={index}>
                                                {info.tagInfoTypeId ?
                                                    <Autocomplete
                                                        id={index.toString()}
                                                        options={tagInfoTypes}
                                                        className="fab_input h-10 p-1 w-1/2"
                                                        onChange={onInformationChange}
                                                        renderInput={(params) => <TextField {...params} key={params.id}
                                                                                            data-index={index} label=""
                                                                                            variant={'standard'}/>}/>
                                                    :
                                                    <p className="fab_input h-10 p-1 w-1/2">{info.label}</p>
                                                }
                                                {
                                                    fabOrderType.useOrder ?
                                                        <select onChange={onInformationInputTypeChange}
                                                                data-index={index} className="w-1/2 fab_input m-auto">
                                                            <option>Valeur depuis l'ordre</option>
                                                            <option>Valeur saisie</option>
                                                        </select>
                                                        :
                                                        <p className="w-1/2 fab_input m-auto">Valeur saisie</p>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="w-[33%] h-2/3">
                            <h1 className="text-xl">[FACTICE] Liste des roles concernés : </h1>
                            <p className="bg-fuchsia-100 rounded">
                                <b>Si aucun rôle n'est défini, alors tout le monde aura accès a cette procédure</b>
                            </p>
                            <div className="w-1/2 flex flex-col gap-2 m-auto">
                                <Autocomplete
                                    options={roles}
                                    className="fab_input h-10 p-1 w-full"
                                    onChange={onRoleChange}
                                    renderInput={(params) => <TextField {...params} label=""
                                                                        variant={'standard'}/>}/>
                                <Button onClick={addRole}
                                        leftIcon={<PlusCircleIcon className="h-12 w-12 p-2"/>}
                                        text="Ajouter le role" className="p-0"/>
                            </div>
                            {
                                fabOrderType.Roles && fabOrderType.Roles.length > 0 &&
                                fabOrderType.Roles.map((role) => (
                                    <div className={"border-b-2 pb-3 mt-3 flex"} key={role.id}>
                                        <p className="fab_input h-10 p-1 w-1/2">{role.Role.label}</p>
                                        <FCheckBox onChange={updateRoleWebAccess} data={role.Role.id}
                                                   className={"border border-fab_congress-blue-950"}
                                                   label={"Création d'ordre"}/>
                                        <FCheckBox onChange={updateRoleMobileAccess} data={role.Role.id}
                                                   className={"border border-fab_congress-blue-950"}
                                                   label={"Utilisation mobile"}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="w-1/3 flex m-auto justify-between">
                        {step < 1 ?
                            <>
                                <Button onClick={prevStep} disabled={step === 0}
                                        leftIcon={<ArrowLeftIcon className="h-12 w-12 p-2"/>} text="Précèdent"
                                        className="mb-3 mt-3 w-36"/>
                                <Button onClick={nextStep} disabled={fabOrderType.label == ""} leftIcon={<ArrowRightIcon className="h-12 w-12 p-2"/>}
                                        text="Suivant"
                                        className="mb-3 mt-3 w-36"/>
                            </>
                            :
                            <>
                                <Button onClick={prevStep}
                                        leftIcon={<ArrowLeftIcon className="h-12 w-12 p-2"/>} text="Précèdent"
                                        className="mb-3 mt-3 w-36"/>
                                <Button onClick={onValidAddFabOrderType}
                                        leftIcon={<CheckCircleIcon className="h-12 w-12 p-2"/>}
                                        text="Valider" loading={loading} className="mb-3 mt-3 w-36"/>
                            </>
                        }
                    </div>
                </>
            </FModal>
        ))
};