// noinspection JSUnusedLocalSymbols

import React, {useEffect, useState} from "react";
import {FabOrder} from "../../interfaces/FabOrder";
import {GridRowModesModel} from "@mui/x-data-grid";
import {useFabOrderLineMovements} from "../../api/base_apis";
import {useFabOrdersApi} from "../../api/base_apis";
import {useFabApp} from "../../hooks/useFabApp";
import {FDataGrid} from "../ui/elements/FDataGrid";
import {useParams} from "react-router-dom";

// noinspection JSUnusedLocalSymbols
export const FabOrderMovementsPage = () => {
    const {id} = useParams()
    const {specificIdentifierChosen} = useFabApp()
    const [fabOrder, setFabOrder] = useState<FabOrder>()
    const { getItemById: getFabOrder, loading,data: fabOrders} = useFabOrdersApi()
    const {data: fabOrderLineMovements} = useFabOrderLineMovements()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [rows, setRows] = useState<any[]>([])
    const [columns, setColumns] = useState([
        { field: 'id', headerName: '#', width: 50 },
        { field: 'tagId', headerName: 'TAG ID', width: 300},
        { field: 'Tag', headerName: 'EPC', width: 300, valueGetter: (params: any) => {return params.rfidValue} },
        { field: 'User', headerName: 'Utilisateur', width: 300, valueGetter: (params: any) => {return params.login} }
    ])
    useEffect(() => {
        if (fabOrders.length <= 0) return
        if (!id) return
        console.log("hello" + id)
        let fabO = getFabOrder(parseInt(id))
        setFabOrder(fabO)
    }, [fabOrders, id]);
    useEffect(() => {
        if (fabOrderLineMovements.length == 0) return
        setRows(fabOrderLineMovements)
    }, [fabOrder]);
    return (<div className="w-full h-full bg-fab_app_container flex flex-col">
        <div className="panel_page_entete">
           {fabOrder != null
               && <span className="text-2xl m-auto"> Mouvements de l'ordre : {fabOrder.internalOrderId.toUpperCase()}</span>
           }
        </div>
        <div className="w-full">
            { fabOrder != null &&
                <div className="bg-fab_congress-blue-200 flex w-full">
                    <p className="text-2xl border-b-2 border-r-2 border-black text-center flex-1">ID Ligne : {specificIdentifierChosen}</p>
                    <p className="text-2xl border-b-2 border-r-2 border-black text-center flex-1"> QUANTITÉ
                        EXECUTÉE : {fabOrder.Lines[0].quantityDone}/{fabOrder.Lines[0].quantity}</p>
                    <p className="text-2xl border-b-2 w-3/12 border-r-2 border-black text-center flex-1">
                        LOT : {fabOrder.Lines[0].batch}</p>
                </div>
            }
        </div>
        {rowModesModel &&
            <div className="w-full flex-1">
                <FDataGrid
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    deleteRow={(id: number) => {}}
                    rows={rows}
                    loading={loading}
                    columns={columns}
                />
            </div>
        }
    </div>)
}