import {Button} from "../ui/inputs/Button";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import React, {useEffect, useState} from "react";
import {GridRowModesModel} from "@mui/x-data-grid";
import {useEncodingFormatsApi, useFabOrderTypesApi} from "../../api/base_apis";
import {FDataGrid} from "../ui/elements/FDataGrid";
import {FModal} from "../ui/elements/FModal";
import {FabOrderTypeEditor} from "./editors/FabOrderTypeEditor";
import {EncodingFormatEditor} from "./editors/EncodingFormatEditor";

export const EncodingFormatsPage = () => {
    const {data: encodingFormats, loading: loadingEncodingFormats, error: error, resetApiError, updateItem: updateEncodingFormat, deleteItem: deleteEncodingFormat, refreshItems: refreshEncodingFormats} = useEncodingFormatsApi();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalCreateEncodingFormat, setModalCreateEncodingFormat] = useState<boolean>(false)
    const columns: any[] = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'label', headerName: 'Nom', type: 'string', width: 370, editable:true},
    ]
    const openModalCreateFabOrderType = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!modalCreateEncodingFormat) setModalCreateEncodingFormat(true);
    }
    const onCloseModal = (e: React.MouseEvent) => {
        setModalCreateEncodingFormat(false)
        refreshEncodingFormats().then()
    }
    const resetError = () => {
        resetApiError()
    }
    useEffect(() => {
        if (!modalCreateEncodingFormat) refreshEncodingFormats().then();
    }, [modalCreateEncodingFormat]);
    return (
        <div className="w-full h-full bg-fab_app_container flex flex-col">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">PARAMÈTRAGE DES TYPES D'ORDRE</span>
            </div>
            <div className="w-1/4 p-5 m-auto">
                <Button text={"Ajouter un type d'encodage"} leftIcon={<PlusCircleIcon/>} onClick={openModalCreateFabOrderType} />
            </div>
            { rowModesModel &&
                <div className="w-full flex-1">
                    <FDataGrid
                        rowModesModel={rowModesModel}
                        setRowModesModel={setRowModesModel}
                        updateRow={updateEncodingFormat}
                        deleteRow={deleteEncodingFormat}
                        rows={encodingFormats}
                        loading={loadingEncodingFormats}
                        columns={columns}
                        confirmDeleteText={"Attention, la suppression d'un format d'encodage peut créer des bugs"}
                    />
                </div>
            }
            { error &&
                <FModal className="bg-white w-2/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl" canClose onClose={resetError} title={"Erreur"}>
                    <div className={"w-full flex flex-col mt-4"}>
                        <p className="p-5 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error.message}</p>
                    </div>
                </FModal>
            }
            {
                modalCreateEncodingFormat &&
                <EncodingFormatEditor onClose={onCloseModal} setModalOpen={setModalCreateEncodingFormat}/>
            }
            {/*{*/}
            {/*    modalAddRoleOpen && <RoleEditor onClose={onCloseAddRoleModal} setModalOpen={setModalAddRoleOpen}/>*/}
            {/*}*/}
        </div>
    )
}