import {
    ArrowsRightLeftIcon, BookmarkSquareIcon, BuildingOfficeIcon,
    CpuChipIcon, DevicePhoneMobileIcon, DocumentMagnifyingGlassIcon, MapPinIcon, PresentationChartLineIcon
} from "@heroicons/react/24/solid";
import {useFabApp} from "../../hooks/useFabApp";
import React from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import {FNavButton} from "../ui/inputs/FNavButton";
import {useFabOrderTypesApi} from "../../api/base_apis";
import {FabOrderType} from "../../interfaces/FabOrderType";
import {
    ArrowCircleDownSharp,
    ArrowDownwardSharp,
    DeveloperMode,
    Key, KeyboardArrowDown,
    LoginOutlined,
    LogoutOutlined,
    Person
} from "@mui/icons-material";
import {GridMenuIcon} from "@mui/x-data-grid";
import {Button} from "../ui/inputs/Button";
import {NavSection} from "./NavSection";
import {getIconForLabel} from "../data/iconsForFabOrderType";

export const NavBar = () => {
    const {siteChosen, setSiteChosen, setIsAuth, user} = useFabApp();
    const {data: fabOrderTypes, loading} = useFabOrderTypesApi()
    const [, setLocalTokenItem] = useLocalStorage('fabToken', "");
    const resetSite = () => {
        setSiteChosen(null);
    };
    const logout = () => {
        setLocalTokenItem("");
        setIsAuth(false);
    };
    return (
        <div
            className={"fab_navbar"}>
            <div className="">
                <div className="w-full flex mt-1 justify-around">
                    <div
                        className="p-2.5 w-16 h-16 bg-fab_congress-blue-800 rounded-full flex flex-col border-2 border-white text-center shadow-xl relative">
                        <span
                            className=" text-3xl m-auto opacity-100 text-white">
                            {user?.fullName?.charAt(0) ?? user?.login?.charAt(0)}
                        </span>
                    </div>
                </div>
                <div className="w-full m-auto pr-5 pl-5 text-white gap-2">
                    <div className="h-full text-black flex-1 rounded-lg m-auto">
                        <b>Utilisateur : </b>{user?.fullName ?? user?.login} <br/>
                        <b>Rôle : </b> {user?.Role?.label}
                    </div>
                    <Button text="Déconnexion" leftIcon={<LogoutOutlined />} className={"h-1/2 w-full"} onClick={logout}/>
                    {/*<div onClick={logout} className="h-1/2 flex-1 bg-fab_congress-blue-500 text-center m-auto fab_button"><p>Déconnexion</p></div>*/}
                </div>
                <div className="text-center w-10/12 m-auto flex align-middle mt-2 ">
                    <span
                        className="m-auto pr-2 pl-2 h-1/2 w-1/2 rounded-lg text-black">Site : </span>
                    <Button onClick={resetSite}  text={siteChosen ? siteChosen.label : "Aucun"}/>
                </div>
            </div>
            <div className="w-full m-auto h-2 bg-fab_congress-blue-500 mt-2 mb-2"/>
            <div className="flex flex-col h-min-full w-full">
                {user != null && user.Role?.webAccess && <>
                    <FNavButton leftIcon={<PresentationChartLineIcon className="h-6 pr-2"/>} text={"Tableau de bord"}
                                route='/dashboard'/>
                    <NavSection title="Fabrication">
                    {
                        fabOrderTypes && fabOrderTypes.length > 0 &&
                        fabOrderTypes.map((value: FabOrderType) => (
                            <FNavButton leftIcon={getIconForLabel(value.label)!.icon}
                                        text={value.label}
                                        route={`fab_order_types/${value.id}`} fabOrderType={value}/>
                        ))
                    }
                    {
                        loading &&
                        <p>Chargement</p>
                    }
                    </NavSection>
                </>
                }
                { user != null && user.Role?.webAccess == true && <>
                    <NavSection title="Production">
                        <FNavButton leftIcon={<ArrowsRightLeftIcon className="h-6 pr-2" />} text={"Mouvements"} route="movements" />
                        <FNavButton leftIcon={<CpuChipIcon className="h-6 pr-2" />} text={"Tags"} route="tags" />
                    </NavSection>

                </>}
                {user != null && user.Role?.adminAccess == true && <>
                    <NavSection title="Logistique">
                        <FNavButton leftIcon={<BuildingOfficeIcon className="h-6 pr-2"/>} text={"Sociétés"}
                                    route="companies"/>
                        <FNavButton leftIcon={<MapPinIcon className="h-6 pr-2"/>} text={"Sites"}
                                    route="sites"/>
                    </NavSection>

                </>}
                {
                    user != null && user.Role?.adminAccess == true && <>
                        <NavSection title="Personnel">
                            <FNavButton leftIcon={<Key className="h-6 pr-2"/>} text={"Rôles"}
                                        route="roles"/>
                            {/*<FNavButton leftIcon={<Groups className="h-6 pr-2"/>} text={"Equipes"}*/}
                            {/*            route='teams'/>*/}
                            <FNavButton leftIcon={<Person className="h-6 pr-2"/>} text={"Utilisateurs"}
                                        route='users'/>
                        </NavSection>
                    </>
                }
                {
                    user != null && user.superUser == true && <>
                        <NavSection title="Paramètrages">
                            {/*<FNavButton leftIcon={<ArchiveBoxIcon className="h-6 pr-2"/>} text={"Types d'article"}*/}
                            {/*            route='item_types'/>*/}
                            {/*<FNavButton leftIcon={<ArchiveBoxIcon className="h-6 pr-2"/>} text={"Articles"}*/}
                            {/*            route='items'/>*/}
                            <FNavButton leftIcon={<BookmarkSquareIcon className="h-6 pr-2"/>} text={"TAG's Informations"}
                                        route='tags_informations'/>
                            {/*<FNavButton leftIcon={<DevicePhoneMobileIcon className="h-6 pr-2"/>} text={"Paramètres"}*/}
                            {/*            route='processes'/>*/}
                            <FNavButton leftIcon={<GridMenuIcon className="h-6 pr-2"/>} text={"Formats d'encodage"}
                                        route='encoding_formats'/>
                            <FNavButton leftIcon={<GridMenuIcon className="h-6 pr-2"/>} text={"Types d'ordre"}
                                        route='fab_order_types'/>
                            <FNavButton leftIcon={<DeveloperMode className="h-6 pr-2"/>} text={"Gestion des terminaux"}
                                        route='devices'/>
                        </NavSection>
                    </>
                }

            </div>


        </div>
    );
};