import {FModal} from "../../ui/elements/FModal";
import React, {FC, useState} from "react";
import {IFModal} from "../../ui/interfaces/elements/IFModal";
import {TextInput} from "../../ui/inputs/TextInput";
import {Button} from "../../ui/inputs/Button";
import {Role} from "../../../interfaces/Role";
import {useRolesApi} from "../../../api/base_apis";
import {FCheckBox} from "../../ui/inputs/FCheckBox";
import {CheckCircleIcon} from "@heroicons/react/24/solid";

export const RoleEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const [newRole, setNewRole] = useState<Role>({
        label: "",
        id: 0,
        webAccess: false,
        mobileAccess: false,
        adminAccess: false,
        teamManager: false
    });
    const {addItem: addRole, loading} = useRolesApi();
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const onCloseFormErrors = () => {
        setFormErrors([]);
    };
    const onValidUser = () => {
        const errors = [];
        if (newRole.label == "") errors.push("Nom obligatoire");
        if (errors.length > 0) {
            setFormErrors(errors);
        } else {
            addRole(newRole).then(r => {
                if (setModalOpen) setModalOpen(false);
            });

        }
    };
    const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setNewRole(prevState => ({...prevState, label: e.target.value}));
    };
    const activeCheckElement = (e: React.ChangeEvent<HTMLInputElement>) => {
        let subject = e.currentTarget.getAttribute('data-custom');
        switch (subject) {
            case "web_access":
                setNewRole(prevState => ({...prevState, webAccess: !prevState.webAccess}));
                break;
            case "mobile_access":
                setNewRole(prevState => ({...prevState, mobileAccess: !prevState.mobileAccess}));
                break;
            case "admin_access":
                setNewRole(prevState => ({...prevState, adminAccess: !prevState.adminAccess}));
                break;
        }
    };
    return (<FModal title={"Ajout d'un rôle"}
                    className="fab_editor_modal"
                    canClose onClose={onClose}>
            <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
                <TextInput value={newRole.label} onChange={onLabelChange} label={"Nom du rôle : "}
                           className={"fab_editor_modal_input"}/>
                <FCheckBox onChange={activeCheckElement} data={"web_access"}
                           className={"border border-fab_congress-blue-950 w-2/3 m-auto"}
                           label={
                               <span>
                            <b>Accès Web</b> (L'utilisateur aura accès au site web aux onglets fabrication et production.)
                        </span>}
                />
                <FCheckBox onChange={activeCheckElement} data={"mobile_access"}
                           className={"border border-fab_congress-blue-950 w-2/3 m-auto"}
                           label={
                               <span>
                            <b>Accès Mobile</b> (L'utilisateur aura accès a l'application mobile)
                        </span>}
                />
                <FCheckBox onChange={activeCheckElement} data={"admin_access"}
                           className={"border border-fab_congress-blue-950 w-2/3 m-auto"}
                           label={
                               <span>
                            <b>Administrateur</b> (L'utilisateur aura accès au site web pour les onglets Logistique et Personnel)
                        </span>}
                />
            </div>
            <Button onClick={onValidUser} leftIcon={<CheckCircleIcon/>} loading={loading} className="mt-2 mb-1 w-1/12"
                    text={"Valider"}/>
            {
                formErrors && formErrors.length > 0 &&
                <FModal
                    className="bg-white w-2/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl"
                    canClose onClose={onCloseFormErrors} title={"Erreurs"}>
                    {
                        formErrors.map(error => (
                            <p className="p-2 m-2 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error}</p>
                        ))
                    }
                </FModal>
            }
        </FModal>
    );

};