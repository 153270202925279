import {useSiteMovements} from "../../api/base_apis";
import {FDataGrid} from "../ui/elements/FDataGrid";
import React from "react";
import {GridRowModesModel} from "@mui/x-data-grid";

export const SiteMovementsPage = () => {
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const {data: movements, loading} = useSiteMovements();
    const columns: any[] = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'Tag', headerName: 'EPC', width: 200, valueGetter: (params: any) => params.rfidValue },
        { field: 'fabOrderTypeLabel', headerName: 'Procédure', width: 200},
        { field: 'fabOrderInternalId', headerName: 'Ordre', width: 220},
        { field: 'User', headerName: 'Utilisateur', width: 500, valueGetter: (params: any) => params.mobileLogin},
    ]
    return (
        <>
            <div className="w-full h-full bg-fab_app_container flex flex-col">
                <div className="panel_page_entete">
                    <span className="text-2xl m-auto">MOUVEMENTS</span>
                </div>
                {/*<FTable data={fabOrders} actions={actions} title="Paramétrage des items" />*/}
                {rowModesModel &&
                    <div className="w-full flex-1">
                        <FDataGrid
                            disableEdit
                            rowModesModel={rowModesModel}
                            setRowModesModel={setRowModesModel}
                            confirmDeleteText={"La suppression entraînera la perte de tous les ordres du site"}
                            rows={movements}
                            loading={loading}
                            columns={columns}/>
                    </div>
                }
            </div>
        </>
    )
}