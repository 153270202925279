import {Button} from "../ui/inputs/Button";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import React, {useEffect, useState} from "react";
import {GridRowModesModel} from "@mui/x-data-grid";
import {useRolesApi} from "../../api/base_apis";
import {FDataGrid} from "../ui/elements/FDataGrid";
import {FModal} from "../ui/elements/FModal";
import {RoleEditor} from "./editors/RoleEditor";

export const RolesPage = () => {
    const {data: roles, loading: loadingRoles, error: error, resetApiError, updateItem: updateRole, deleteItem: deleteRole, refreshItems: refreshRoles} = useRolesApi();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalAddRoleOpen, setModalAddRoleOpen] = useState<boolean>(false)
    const columns: any[] = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'label', headerName: 'Nom', type: 'string', width: 370, editable:true},
        { field: 'adminAccess', headerName: 'Acces Admin', type: 'boolean', width: 100, editable:true},
        { field: 'webAccess', headerName: 'Acces Web', type: 'boolean', width: 100, editable:true},
        { field: 'mobileAccess', headerName: 'Acces Mobile', type: 'boolean', width: 100, editable:true},
        // { field: 'teamManager', headerName: 'Gestionnaire', type: 'boolean', width: 100, editable:true},
    ]
    const onCloseAddRoleModal = (e: React.MouseEvent) => {
        setModalAddRoleOpen(false)
    }
    const openModalAddRole = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!modalAddRoleOpen) setModalAddRoleOpen(true);
    }
    const resetError = () => {
        resetApiError()
    }
    useEffect(() => {
        if (!modalAddRoleOpen) refreshRoles().then();
    }, [modalAddRoleOpen]);
    return (
        <div className="w-full h-full bg-fab_app_container flex flex-col">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">PARAMÈTRAGE DES RÔLES</span>
            </div>
            <div className="w-1/4 p-5 m-auto">
                <Button text={"Ajouter un role"} leftIcon={<PlusCircleIcon/>} onClick={openModalAddRole} />
            </div>
            { rowModesModel &&
                <div className="w-full flex-1">
                    <FDataGrid
                        rowModesModel={rowModesModel}
                        setRowModesModel={setRowModesModel}
                        updateRow={updateRole}
                        deleteRow={deleteRole}
                        rows={roles}
                        loading={loadingRoles}
                        columns={columns}
                    />
                </div>
            }
            { error &&
                <FModal className="bg-white w-2/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl" canClose onClose={resetError} title={"Erreur"}>
                    <div className={"w-full flex flex-col mt-4"}>
                        <p className="p-5 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error.message}</p>
                    </div>
                </FModal>
            }
            {
                modalAddRoleOpen && <RoleEditor onClose={onCloseAddRoleModal} setModalOpen={setModalAddRoleOpen}/>
            }
        </div>
    )
}