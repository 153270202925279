import {Button} from "../ui/inputs/Button";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import React, {useEffect, useState} from "react";
import {
    GridColDef,
    GridRowModesModel, GridRowParams,
} from "@mui/x-data-grid";
import {FDataGrid} from "../ui/elements/FDataGrid";
import {FModal} from "../ui/elements/FModal";
import {useApplicationDevicesApi} from "../../api/base_apis";
import {useFabApp} from "../../hooks/useFabApp";

export const ApplicationDevicesPage = () => {
    const {data: devices, loading, error,getItemById: getDeviceById, deleteItem: deleteDevice,updateItem: updateDevice, refreshItems: refreshDevices, resetApiError} = useApplicationDevicesApi();
    const { user } = useFabApp()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [init, setInit] = useState<boolean>(false)
    const switchAuthorization = (id: number) => {
        if (!user?.superUser) return;
        let foundDevice = getDeviceById(id)
        if (!foundDevice) return;
        foundDevice.authorized = !foundDevice.authorized
        updateDevice(foundDevice).then()
    }
    const columns: any[] = [{ field: 'id', headerName: '#', width: 50 },
        { field: 'label', headerName: 'Nom (Optionnel)', type: 'string', width: 100, editable:true},
        { field: 'deviceIdentifier', headerName: 'Identifiant', type: 'string', width: 150, editable:true},
        { field: 'model', headerName: 'Modèle Lecteur', type: 'string', width: 100, editable:true},
        { field: 'fabtracerVersion', headerName: 'FABTRACER', type: 'string', width: 150, editable:true},
        { field: 'androidVersion', headerName: 'ANDROID', type: 'string', width: 150, editable:true},
        { field: 'buildVersion', headerName: 'BUILD', type:'string', editable: true, width: 200},
        {
            field: 'invert_authorization', headerName: '-', type: 'actions', width: 200, cellClassName: 'actions',
            getActions: (params: GridRowParams) => {
                return [
                    <Button
                        specificColor={params.row.authorized ? "!bg-fab_light_red-400" :"!bg-fab_mint_green-200" }
                        text= {params.row.authorized ? "Révoquer l'accès" :"Autoriser l'accès" }
                        onClick={(e : React.MouseEvent<HTMLButtonElement>) => {
                            //e.stopPropagation()
                            e.currentTarget.parentElement?.parentElement?.parentElement?.focus()
                            switchAuthorization(params.row.id)
                        }}
                    />
                ]
            }
        }]
    return (
        <div className="w-full h-full bg-fab_app_container flex flex-col">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">PARAMÈTRAGE DES UTILISATEURS</span>
            </div>
            {rowModesModel && devices &&
                <div className="w-full flex-1">
                    <FDataGrid
                        rowModesModel={rowModesModel}
                        setRowModesModel={setRowModesModel}
                        deleteRow={deleteDevice}
                        updateRow={updateDevice}
                        rows={devices}
                        loading={loading}
                        columns={columns}
                        showMore={() => {}}
                    />
                </div>
            }
            { error &&
                <FModal className="bg-white w-2/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl" canClose onClose={resetApiError} title={"Erreur"}>
                    <div className={"w-full flex flex-col mt-4"}>
                        <p className="p-5 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error.message}</p>
                    </div>
                </FModal>
            }
        </div>
    )
}