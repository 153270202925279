import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button} from "../ui/inputs/Button";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import {FDataGrid} from "../ui/elements/FDataGrid";
import {useFabOrderTypesApi} from "../../api/base_apis";
import {GridRowModesModel} from "@mui/x-data-grid";
import {useFabApp} from "../../hooks/useFabApp";
import {useFabOrdersApi} from "../../api/base_apis";
import {FabOrderEditor} from "./editors/FabOrderEditor";
import {useNavigate} from "react-router-dom";

export const FabOrderTypePage = () => {
    const { id } = useParams()
    const {data: fabOrders, loading, getItemById: getFabOrderById,deleteItem: deleteFabOrder, updateItem: updateFabOrder, refreshItems: refreshFabOrders} = useFabOrdersApi()
    const {getItemById: getFabOrderType} = useFabOrderTypesApi()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [rows, setRows] = useState<any[]>([])
    const [columns, setColumns] =  useState<any[]>([])
    const navigate = useNavigate()
    const {siteChosen, fabOrderTypeChosen, setFabOrderTypeChosen, setSpecificIdentifierChosen} = useFabApp()
    const openModal = () => {
        setModalOpen(true)
    }
    const onCloseModal = () => {
        setModalOpen(false)
    }
    const showMoreOnFabOrder = (id: number) => {
        if (fabOrderTypeChosen?.useOrder) {
            const selectedFabOrder = getFabOrderById(id)
            setSpecificIdentifierChosen(selectedFabOrder?.Lines[0].id ?? 0)
            navigate('/dashboard/fab_order_lines/' + fabOrderTypeChosen.id + "/" + id)
            // Show Movements Lines
        }
    }
    useEffect(() => {
        if (!modalOpen)
            refreshFabOrders().then()
    }, [modalOpen]);
    useEffect(() => {
        if(fabOrders.length == 0 && id != null && fabOrderTypeChosen == null) {
            const fabOrderType = getFabOrderType(parseInt(id))
            console.log(fabOrderType)
            setFabOrderTypeChosen(fabOrderType ?? null)
        }
    }, [siteChosen]);
    useEffect(() => {
        // if (fabOrders.length > 0) {
            if (fabOrderTypeChosen?.useOrder) {
                setColumns( [
                    { field: 'id', headerName: '#', width: 50 },
                    { field: 'internalOrderId', headerName: 'Code Ordre', width: 700, editable:true },
                    { field: 'Lines', headerName: 'Lignes', width: 300, valueGetter: ( (value: any) => {
                        if (value.length == 1) {
                            return value[0].Movements.length
                        } else {
                            return value.length
                        }
                        }) },
                ])
                setRows(fabOrders)
            } else {
                setColumns( [
                    { field: 'id', headerName: '#', width: 50 },
                    { field: 'quantityDone', headerName: 'Quantité', width: 300 },
                    { field: 'Movements', headerName: 'Mouvements', width: 300, valueGetter: ( (value: any) => {
                        return value.length
                    }) },
                ])
                setRows(fabOrders[0].Lines)
            }
        // } else {
        //     setRows([])
        // }
    }, [fabOrders]);
    return(
        <div className="w-full h-full bg-fab_app_container flex flex-col">
            <div className="panel_page_entete">
                { <span className="text-2xl m-auto">{fabOrderTypeChosen?.label.toUpperCase() ?? "ORDRE"}</span>}
            </div>
            { fabOrderTypeChosen?.useOrder && <div className="w-1/4 p-5 m-auto">
                    <Button onClick={openModal} text={"Ajouter un ordre"}
                        leftIcon={<PlusCircleIcon />}/>
                </div> }

                {rowModesModel &&
                    <div className="w-full flex-1">
                <FDataGrid
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    updateRow={updateFabOrder}
                    deleteRow={deleteFabOrder}
                    rows={rows}
                    loading={loading}
                    columns={columns}
                    showMore={showMoreOnFabOrder}
                /></div>}
        {
            fabOrderTypeChosen != null && modalOpen && <FabOrderEditor onClose={onCloseModal} setModalOpen={setModalOpen} orderFabType={fabOrderTypeChosen}/>
        }
    </div>)
}