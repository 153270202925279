import React, {FC, useEffect, useState} from "react";
import { FModal } from "../../ui/elements/FModal";
import {TextInput} from "../../ui/inputs/TextInput";
import {FCheckBox} from "../../ui/inputs/FCheckBox";
import {Button} from "../../ui/inputs/Button";
import {ArrowLeftIcon, ArrowRightIcon, CheckCircleIcon} from "@heroicons/react/24/solid";
import {TagInfoType} from "../../../interfaces/TagInfoType";
import {useTagInfoTypesApi} from "../../../api/tagInfoTypesApi";
import {IFModal} from "../../ui/interfaces/elements/IFModal";
import {useEncodingFormatsApi} from "../../../api/base_apis";
import {EncodingFormat} from "../../../interfaces/EncodingFormat";

export const EncodingFormatEditor : FC<IFModal> = ({onClose,setModalOpen}) => {
    const [newEncodingFormat, setNewEncodingFormat] = useState<EncodingFormat>({
        Infos: [],
        id: 0,
        size: 0,
        label: "",
        orderCompany: undefined,
        orderSite: undefined,
        orderBatch: undefined,
        orderTagBatchId: undefined
    })
    const {addItem: addEncodingFormat, loading} = useEncodingFormatsApi()
    const {tagInfoTypes} = useTagInfoTypesApi()
    const [lastOrder, setLastOrder] = useState<number>(-1)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [selectedTagInfo, setSelectedTagInfo] = useState<number>(-1)
    const [itemTagInfos, setItemTagInfos] = useState<TagInfoType[]>([])
    const [activesTagInfos, setActivesTagInfos] = useState<TagInfoType[]>([])

    useEffect(() => {
        if (tagInfoTypes) {
            setItemTagInfos(tagInfoTypes)
        }
    }, [tagInfoTypes]);
    const onChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setNewEncodingFormat(prevState => ({
            ...prevState,
            label: e.target.value
        }))
    }
    const enableCompany = () => {
        if (newEncodingFormat.orderCompany == undefined) {
            let newOrder = lastOrder + 1
            setNewEncodingFormat(prevState => {
                return {
                    ...prevState,
                    size: newOrder,
                    orderCompany: newOrder
                }
            })
            setLastOrder(newOrder)
        } else {
            setNewEncodingFormat(prevState => {
                return {
                    ...prevState,
                    size: lastOrder - 1,
                    orderCompany: undefined
                }
            })
            setLastOrder(prevState => prevState - 1)
        }
    }
    const enableSite = () => {
        if (newEncodingFormat.orderSite == undefined) {
            let newOrder = lastOrder + 1
            setNewEncodingFormat(prevState => {
                return {
                    ...prevState,
                    size: newOrder,
                    orderSite: newOrder
                }
            })
            setLastOrder(newOrder)
        } else {
            setNewEncodingFormat(prevState => {
                return {
                    ...prevState,
                    size: lastOrder - 1,
                    orderSite: undefined
                }
            })
            setLastOrder(prevState => prevState - 1)
        }
    }
    const enableBatch = () => {
        let newOrder = lastOrder + 1
        if (newEncodingFormat.orderBatch == undefined) {
            setNewEncodingFormat(prevState => {
                return {
                    ...prevState,
                    size: newOrder,
                    orderBatch: newOrder
                }
            })
            setLastOrder(newOrder)
        } else {
            setNewEncodingFormat(prevState => {
                return {
                    ...prevState,
                    size: lastOrder - 1,
                    orderBatch: undefined
                }
            })
            setLastOrder(prevState => prevState - 1)
        }
    }
    const enableTagBatchId = () => {
        let newOrder = lastOrder + 1
        if (newEncodingFormat.orderTagBatchId == undefined) {
            setNewEncodingFormat(prevState => {
                return {
                    ...prevState,
                    size: newOrder,
                    orderTagBatchId: newOrder
                }
            })
            setLastOrder(newOrder)
        } else {
            setNewEncodingFormat(prevState => {
                return {
                    ...prevState,
                    size: lastOrder - 1,
                    orderTagBatchId: undefined
                }
            })
            setLastOrder(prevState => prevState - 1)
        }
    }
    const addTagInfo = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setSelectedTagInfo(-1)
        let indexInfoInArray = itemTagInfos.findIndex(info => info.id === selectedTagInfo)
        if (indexInfoInArray >= 0) {
            let infoInArray = itemTagInfos.find(info => info.id === selectedTagInfo)
            if (infoInArray) {
                let currentItemTagInfos = itemTagInfos
                currentItemTagInfos.splice(indexInfoInArray, 1)
                setItemTagInfos(currentItemTagInfos)
                setActivesTagInfos([...activesTagInfos, infoInArray])
                let newOrder = lastOrder + 1
                setNewEncodingFormat(prevState => ({
                    ...prevState,
                    Infos: [
                        ...prevState.Infos,
                        {
                            id: 0,
                            order: newOrder,
                            TagInfoType: infoInArray
                        }
                    ]
                }))
                setLastOrder(newOrder)
            }

        }
    }
    const removeTagInfo = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const actualSelectionIndex = selectedTagInfo
        setSelectedTagInfo(-1)
        let indexInfoInArray = activesTagInfos.findIndex(info => info.id === actualSelectionIndex)
        let infoInObject = newEncodingFormat.Infos.findIndex(format => format.TagInfoType?.id == actualSelectionIndex)
        console.log(infoInObject)
        console.log(indexInfoInArray)
        if (indexInfoInArray >= 0 && infoInObject >= 0) {
            let infoInArray = activesTagInfos.find(info => info.id === actualSelectionIndex)
            if (infoInArray) {
                let currentActiveTagInfos = activesTagInfos
                currentActiveTagInfos.splice(indexInfoInArray, 1)
                setActivesTagInfos(currentActiveTagInfos)
                setItemTagInfos([...tagInfoTypes, infoInArray])
                let newOrder = lastOrder - 1
                setNewEncodingFormat(prevState => ({
                    ...prevState,
                    Infos: [
                        ...prevState.Infos.splice(infoInObject,1)
                    ]
                }))
                setLastOrder(newOrder)
            }
        }
    }
    // const removeTagInfo = (e: React.MouseEvent<HTMLButtonElement>) => {
    //     e.preventDefault()
    //     const actualSelectionIndex = selectedTagInfo
    //
    //     setSelectedTagInfo(-1)
    //     let indexInfoInArray = activesTagInfos.findIndex(info => info.id === selectedTagInfo)
    //     if (indexInfoInArray >= 0) {
    //         let infoInArray = activesTagInfos.find(info => info.id === selectedTagInfo)
    //         if (infoInArray) {
    //             let currentActivesTagInfos = activesTagInfos
    //             currentActivesTagInfos.splice(indexInfoInArray, 1)
    //             setActivesTagInfos(currentActivesTagInfos)
    //             setItemTagInfos([...itemTagInfos, infoInArray])
    //             let currentProcessData = processData
    //             let subjectExist = currentProcessData.findIndex(e => e.label === infoInArray?.label)
    //             if (subjectExist >= 0) {
    //                 currentProcessData.splice(subjectExist, 1)
    //                 setProcessData(currentProcessData)
    //             }
    //         }
    //
    //     }
    // }
// Fonction pour décaler les ordres d'un en avant
    const moveOrdersForward = () => {
        const newInfos = [...newEncodingFormat.Infos];

        // Décaler les ordres dans Infos
        newInfos.forEach(info => {
            if (info.order !== undefined) {
                info.order++;
            }
        });

        // Décaler les ordres de orderCompany, orderSite, orderTagBatchId s'ils sont définis
        if (newEncodingFormat.orderCompany !== undefined) {
            newEncodingFormat.orderCompany++;
        }
        if (newEncodingFormat.orderSite !== undefined) {
            newEncodingFormat.orderSite++;
        }
        if (newEncodingFormat.orderTagBatchId !== undefined) {
            newEncodingFormat.orderTagBatchId++;
        }

        setNewEncodingFormat(prevState => ({ ...prevState, Infos: newInfos }));
    };

// Fonction pour décaler les ordres d'un en arrière
    const moveOrdersBackward = () => {
        const newInfos = [...newEncodingFormat.Infos];

        // Décaler les ordres dans Infos
        newInfos.forEach(info => {
            if (info.order !== undefined) {
                info.order--;
            }
        });

        // Décaler les ordres de orderCompany, orderSite, orderTagBatchId s'ils sont définis
        if (newEncodingFormat.orderCompany !== undefined) {
            newEncodingFormat.orderCompany--;
        }
        if (newEncodingFormat.orderSite !== undefined) {
            newEncodingFormat.orderSite--;
        }
        if (newEncodingFormat.orderTagBatchId !== undefined) {
            newEncodingFormat.orderTagBatchId--;
        }

        setNewEncodingFormat(prevState => ({ ...prevState, Infos: newInfos }));
    };

    // const upEncodingProcessItem = (index: number) => {
    //     if (index == lastOrder) return
    //     let findInfo = newEncodingFormat.Infos.findIndex(i => i.order == index)
    //
    //     if (findInfo >= 0)
    //     {
    //         const updatedInfos: EncodingFormatInfo[] = []
    //         for (let i = 0; i < lastOrder; i)
    //         newEncodingFormat.Infos.map(info => {
    //             let newInfo = info
    //             if (newInfo.order == index) {
    //                 newInfo.order++
    //             } else if(newInfo.order == index + 1) {
    //                 return newInfo.order--
    //             }
    //             updatedInfos.push(info)
    //         });
    //         setNewEncodingFormat(prevState => ({
    //             ...prevState,
    //             Infos: updatedInfos
    //         }));
    //     }
    //
    //     if (newEncodingFormat.orderCompany == index)
    //         setNewEncodingFormat( prevState => ({...prevState, orderCompany: undefined}))
    //     else if (newEncodingFormat.orderSite == index)
    //         setNewEncodingFormat( prevState => ({...prevState, orderSite: undefined}))
    //     else if (newEncodingFormat.orderTagBatchId == index)
    //         setNewEncodingFormat( prevState => ({...prevState, orderTagBatchId: undefined}))
    //
    //
    //     if (findInfo >= 0)
    //         setNewEncodingFormat(prevState => ({...prevState, Infos: prevState.Infos.splice(findInfo, 1)}))
    //     if (newEncodingFormat.orderCompany == index)
    //         setNewEncodingFormat( prevState => ({...prevState, orderCompany: undefined}))
    //     else if (newEncodingFormat.orderSite == index)
    //         setNewEncodingFormat( prevState => ({...prevState, orderSite: undefined}))
    //     else if (newEncodingFormat.orderTagBatchId == index)
    //         setNewEncodingFormat( prevState => ({...prevState, orderTagBatchId: undefined}))
    //
    //
    // }
    // const upEncodingProcessItem = (index: number) => {
    //     if (index == 0 ) return
    // }
    // const upEncodingProcessItem = (order: number) => {
    //     console.log("UP")
    //     console.log(order)
    //     const actualEncodingProcessItems = processData;
    //     const item = actualEncodingProcessItems.find(item => item.order === order);
    //     if (item === undefined) return
    //     if (item.order === 0) return
    //     const precedingItem = actualEncodingProcessItems.find(item => item.order === order - 1);
    //     if (precedingItem === undefined) return
    //
    //     const itemIndex = actualEncodingProcessItems.findIndex(item => item.order === order); // Use strict equality (===)
    //
    //     item.order = item.order - 1
    //     precedingItem.order = precedingItem.order + 1
    //     const restItems = precedingItem.order == actualEncodingProcessItems.length - 1 ? [] :
    //         actualEncodingProcessItems.slice(itemIndex+1,actualEncodingProcessItems.length)
    //
    //     console.log(restItems)
    //     const updatedData = item.order == 0 ?
    //         [item, precedingItem, ...restItems] :
    //         [...actualEncodingProcessItems.slice(0,itemIndex-1), item, precedingItem, ...restItems]
    //     setProcessData(updatedData);
    // };
    // const downEncodingProcessItem = (order: number) => {
    //     // const actualEncodingProcessItems = processData;
    //     // const item = actualEncodingProcessItems.find(item => item.order === order);
    //     // if (item === undefined) return
    //     // if (item.order === actualEncodingProcessItems.length - 1) return
    //     // const followingItem = actualEncodingProcessItems.find(item => item.order === order + 1);
    //     // if (followingItem === undefined) return
    //     //
    //     // const itemIndex = actualEncodingProcessItems.findIndex(item => item.order === order);
    //     //
    //     // item.order = item.order + 1
    //     // followingItem.order = followingItem.order - 1
    //     // const restItems = item.order == actualEncodingProcessItems.length - 1 ? [item] :
    //     //     actualEncodingProcessItems.slice(itemIndex+1,actualEncodingProcessItems.length)
    //     //
    //     // console.log(restItems)
    //     // const updatedData = item.order  == actualEncodingProcessItems.length - 1 ?
    //     //     [followingItem, ...restItems] :
    //     //     [...actualEncodingProcessItems.slice(0,itemIndex-1), item, followingItem, ...restItems]
    //     // console.log("set")
    //     // console.log(updatedData)
    //     // setProcessData(updatedData);
    // };
    const onValidAddEncodingFormat = () => {
        addEncodingFormat(newEncodingFormat).then(() => {
                if (setModalOpen) setModalOpen(false)
            }
        )
    }
    return(
        <FModal canClose onClose={onClose}
                className="fab_editor_modal"
                title={`Création d'une procédure d'encodage`}>
            <div className="w-full h-6">
                <span className="text-red-700">{errorMessage != "" ? errorMessage : null}</span>
            </div>
            <div className="w-full justify-center gap-10">
                <TextInput value={newEncodingFormat.label} onChange={onChangeLabel} label={"Nom de la procédure"}
                           className={"fab_editor_modal_input !mb-5"}/>
            </div>

            <div className="w-full flex justify-between gap-2">
                <div className="w-full border shadow-2xl rounded-md flex flex-wrap gap-1">
                    <span className="w-full">Valeurs Forcées</span>
                    <div
                        className={`flex w-1/3 h-16 m-auto mt-1 mb-1 p-1 shadow-sm rounded-lg border-4 ${newEncodingFormat.orderCompany ? 'border-fab_mint_green-200' : ""} `}>
                        <FCheckBox onVoidChange={enableCompany} label={"Société"} className="w-full"/>
                    </div>
                    <div
                        className={`flex w-1/3 h-16 m-auto mt-1 mb-1 p-1 justify-between shadow-sm rounded-lg border-4 ${newEncodingFormat.orderSite ? 'border-fab_mint_green-200' : ""} `}>
                        <FCheckBox onVoidChange={enableSite} label={"Site"} className="w-full"/>
                    </div>
                    <div
                        className={`flex w-1/3 h-16 m-auto mt-1 mb-1 p-1 justify-between shadow-sm rounded-lg border-4 ${newEncodingFormat.orderBatch ? 'border-fab_mint_green-200' : ""} `}>
                        <FCheckBox onVoidChange={enableBatch} label={"Lot"} className="w-full"/>
                    </div>
                    <div
                        className={`flex w-1/3 h-16 m-auto mt-1 mb-1 p-1 justify-between shadow-sm rounded-lg border-4 ${newEncodingFormat.orderTagBatchId ? 'border-fab_mint_green-200' : ""} `}>
                        <FCheckBox onVoidChange={enableTagBatchId} label={"Numéro tag de l'ordre"} className="w-full"/>
                    </div>
                </div>
                <div className="w-full p-2 h-96 flex justify-center border shadow-2xl rounded-md ">
                    <div className="w-2/6 h-full bg-fab_mint_green-600 flex flex-col">
                        {itemTagInfos && itemTagInfos.length > 0 &&
                            itemTagInfos.map(tagInfo => (
                                <>
                                    <div
                                        onClick={() => setSelectedTagInfo(tagInfo.id)}
                                        data-tagInfoTypeId={tagInfo.id}
                                        className={`m-2 border-2 rounded-md ${selectedTagInfo == tagInfo.id ? 'border-fab_congress-blue-500 scale-105' : 'hover:border-fab_congress-blue-500'}`}>{tagInfo.label}
                                    </div>
                                </>
                            ))
                        }
                    </div>
                    <div className="w-1/6 h-full flex flex-col justify-around">
                        <Button soloIcon={<ArrowRightIcon className="w-12 h-4"/>} onClick={addTagInfo}></Button>
                        <Button soloIcon={<ArrowLeftIcon className="w-12 h-4"/>} onClick={removeTagInfo}></Button>
                    </div>
                    <div className="w-2/6 h-full bg-fab_mint_green-600">
                        {activesTagInfos && activesTagInfos.length > 0 &&
                            activesTagInfos.map(tagInfo => (
                                <>
                                    <div
                                        onClick={() => setSelectedTagInfo(tagInfo.id)}
                                        data-tagInfoTypeId={tagInfo.id}
                                        className={`m-2 border-2 rounded-md ${selectedTagInfo == tagInfo.id ? 'border-fab_congress-blue-500 scale-105' : 'hover:border-fab_congress-blue-500'}`}>{tagInfo.label}
                                    </div>
                                </>
                            ))
                        }
                    </div>

                </div>
            </div>
            {/*<div className="w-full mt-10 h-32 justify-center border shadow-2xl rounded-md ">*/}
            {/*    <p className="text-center">Ordre encodage</p>*/}
            {/*    <div className="flex m-auto gap-4 overflow-x-auto">*/}
            {/*        {newEncodingFormat.Infos && newEncodingFormat.Infos.length > 0 &&*/}
            {/*            newEncodingFormat.Infos.map(processItem => (*/}
            {/*                <div*/}
            {/*                    className="w-min-1/6 w-1/6 h-26 bg-fab_mint_green-600 border-2 transition rounded-sm ease-in animate-slideIn flex flex-col justify-center">*/}
            {/*                    <span className="bg-gray-200 rounded w-1/12 m-auto mt-1 h-2/4">{processItem.order}</span>*/}
            {/*                    <span className="text-center h-1/4">{processItem.}</span>*/}
            {/*                    <div className="w-full flex m-auto pr-10 pl-10 mb-1 h-2/4">*/}
            {/*                        <Button onClick={() => {}} soloIcon={<ArrowLeftIcon className="h-4 w-12"/>}/>*/}
            {/*                        <Button onClick={() => {}} soloIcon={<ArrowRightIcon className="h-4 w-12"/>}/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            ))*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="w-full mt-10 h-44 justify-center border shadow-2xl rounded-md ">
                <p className="text-center">Ordre encodage</p>
                <div className="flex m-auto gap-4 overflow-x-auto">
                    {lastOrder > -1 &&
                        Array.from({ length: lastOrder + 1 }, (_, index) => (
                            <div
                                className="w-min-1/6 w-1/6 h-32 bg-fab_mint_green-600 border-2 transition rounded-sm ease-in animate-slideIn flex flex-col justify-center">
                                <span
                                    className="bg-gray-200 rounded w-1/12 m-auto mt-1 h-1/4">{index}</span>
                                {
                                    index == newEncodingFormat.orderCompany &&
                                        <span className="text-center h-1/4">Société</span>
                                    || index == newEncodingFormat.orderSite &&
                                    <span className="text-center h-1/4">Site</span>
                                    || index == newEncodingFormat.orderBatch &&
                                    <span className="text-center h-1/4">Lot</span>
                                    || index == newEncodingFormat.orderTagBatchId &&
                                    <span className="text-center h-1/4">Numéro TAG</span>
                                    || newEncodingFormat.Infos.find(i => i.order == index) &&
                                    <span className="text-center h-1/4">{newEncodingFormat.Infos.find(i => i.order == index)?.TagInfoType?.label}</span>
                                }

                                <div className="w-full flex m-auto pr-10 pl-10 mb-1 h-1/4">
                                    <Button onClick={() => {}} soloIcon={<ArrowLeftIcon className="h-4 w-12"/>}/>
                                    <Button onClick={() => {}} soloIcon={<ArrowRightIcon className="h-4 w-12"/>}/>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Button onClick={onValidAddEncodingFormat} leftIcon={<CheckCircleIcon />} text={"Valider"} loading={loading} className={"mt-2 mb-1 w-1/6"}/>
        </FModal>
    )
}