import '../fab_ui.css'
import React, { FC } from 'react'
import { IExtendedTextInput } from '../interfaces/inputs/IExtendedInput'

export const TextInput: FC<IExtendedTextInput> = ({value, defaultValue, className, onChange, label, setterChange, data, maxLength, password}) => {
    //const uuid = randomUUID()
    const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        if(setterChange == undefined) return;
        setterChange(e.currentTarget.value);
    }
    return(
        <div className={className}>
            {label && <div className="w-1/3 text-left m-auto border-b pb-2">{label}</div>}
            <input maxLength={maxLength ?? -1} type={password ? "password" : "text"} data-custom={data} onChange={onChange ?? onChangeHandler} defaultValue={defaultValue} value={value} className={`w-2/3 fab_input`}/>
        </div>
    )
}