import {useEffect, useState} from "react";
import {BASE_DELETE_OPTION, BASE_GET_OPTIONS, BASE_POST_OPTIONS, BASE_PUT_OPTIONS, BASE_URL} from "./static_vars";
import useLocalStorage from "../hooks/useLocalStorage";
import {TagInfoType} from "../interfaces/TagInfoType";
export function useTagInfoTypesApi() {
    const [tagInfoTypes, setTagInfoTypess] = useState<TagInfoType[]>([])
    const [tokenLocalItemValue] = useLocalStorage("fabToken", "")
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const addTagInfoType = async(element: TagInfoType) => {
        try {
            setLoading(true)
            const opts = BASE_POST_OPTIONS;
            opts.headers = new Headers()
            opts.headers.append('Authorization', `Bearer ${tokenLocalItemValue}`)
            opts.body = new FormData();
            opts.body.append('newTagInfoType', JSON.stringify(element))
            const response = await fetch(BASE_URL + 'web/tag_info_type', opts);
            if (!response.ok || response.body === null) {
                throw new Error('Erreur lors de l\'ajout de la valeur tag');
            } else {
                setLoading(false)
            }
        } catch(e: any) {
            setLoading(false)
        }
    }
    const updateTagInfoType = async <T>(element: T): Promise<T | undefined> => {
        try {
            const opts = BASE_PUT_OPTIONS;
            const updateTagInfoType = element as TagInfoType;
            opts.headers = {Authorization: `Bearer ${tokenLocalItemValue}`};
            opts.body = new FormData();
            opts.body.append('tagInfoType', JSON.stringify(updateTagInfoType))
            const response = await fetch(BASE_URL + 'web/tag_info_type', opts);
            if (!response.ok || response.body === null) {
                throw new Error('Erreur lors la mise à jour de la valeur tag');
            } else {
                let editedTagInfoType: T = await response.json();
                return Promise.resolve(editedTagInfoType)
            }
        } catch {
            return Promise.resolve(undefined)
        }
    }
    const deleteTagInfoType = async (id: number) => {
        try {
            setLoading(true)
            const opts = BASE_DELETE_OPTION;
            opts.headers = new Headers()
            opts.headers.append('Authorization', `Bearer ${tokenLocalItemValue}`)
            opts.body = new FormData();
            opts.body.append('tagInfoTypeId', id.toString())
            const response = await fetch(BASE_URL + 'web/tag_info_type', opts);
            if (!response.ok || response.body === null) {
                throw new Error('Erreur lors de la suppression de la valeur tag');
            } else {
                const data = await response.json();
                setLoading(false)
                setTagInfoTypess(data);
            }
        } catch(e: any) {
            setLoading(false)
        }
    }
    const fetchAllTagInfoType = async () => {
        if (!tokenLocalItemValue) return
        try {
            const opts = BASE_GET_OPTIONS;
            opts.headers = new Headers()
            opts.headers.append('Authorization', `Bearer ${tokenLocalItemValue}`)
            const response = await fetch(BASE_URL + 'web/tag_info_types', opts);
            if (!response.ok || response.body === null) {
                throw new Error('Erreur lors de la récupération des valeur tags');
            }
            const data = await response.json();
            setTagInfoTypess(data);
        } catch (error: any) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const refreshTagInfoTypes = async() => {
        setLoading(true)
        fetchAllTagInfoType().then(() => setLoading(false));
    }
    useEffect(() => {
        if (tagInfoTypes.length === 0) {
            fetchAllTagInfoType().then(() => setLoading(false));
        }
    }, [tokenLocalItemValue]);
    return {tagInfoTypes, fetchAllTagInfoType, loading, error, addTagInfoType, updateTagInfoType, deleteTagInfoType, refreshTagInfoTypes}
}