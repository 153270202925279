import {Button} from "../ui/inputs/Button";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import React, {useEffect, useState} from "react";
import {TagInformationsEditor} from "./editors/TagInformationsEditor";
import {FDataGrid} from "../ui/elements/FDataGrid";
import {GridRowModesModel} from "@mui/x-data-grid";
import {useTagInfoTypesApi} from "../../api/tagInfoTypesApi";

export const TagInformationsPage= () => {
    const {tagInfoTypes, loading, updateTagInfoType, deleteTagInfoType, refreshTagInfoTypes} = useTagInfoTypesApi();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const columns: any[] = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'label', headerName: 'Label', type: 'string', width: 750, editable:true},
    ]
    const onCloseModal = (e: React.MouseEvent) => {
        setModalOpen(false)
    }
    useEffect(() => {
        if (!modalOpen) refreshTagInfoTypes().then()
    }, [modalOpen]);
    const openModal = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!modalOpen) setModalOpen(true);
    }
    return (
        <div className="w-full h-full bg-fab_app_container flex flex-col">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">PARAMÈTRAGE DES INFORMATIONS TAG</span>
            </div>
            <div className="w-1/4 p-5 m-auto">
                <Button onClick={openModal} text={"Ajouter une information"}
                        leftIcon={<PlusCircleIcon className="h-12 w-10 p-1"/>}/>
            </div>
            {rowModesModel &&
                <div className="w-full flex-1">
                    <FDataGrid
                        rowModesModel={rowModesModel}
                        setRowModesModel={setRowModesModel}
                        updateRow={updateTagInfoType}
                        deleteRow={deleteTagInfoType}
                        rows={tagInfoTypes}
                        loading={loading}
                        columns={columns}
                        showMore={(id: number) => console.log(id)}
                    />
                </div>
            }
            {
                modalOpen && <TagInformationsEditor onClose={onCloseModal} setModalOpen={setModalOpen}/>
            }
        </div>
    )
}