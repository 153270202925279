import '../fab_ui.css'
import React, {FC, useEffect, useState} from 'react';
import {IButton} from "../interfaces/inputs/IButton";
import {useNavigate} from "react-router-dom";
import {useFabApp} from "../../../hooks/useFabApp";
import {FabOrderType} from "../../../interfaces/FabOrderType";
export const FNavButton:
    FC<{fabOrderType?: FabOrderType | null} & IButton> =
    ({fabOrderType, text, leftIcon, onClick, route}) => {
        const navigate = useNavigate();
        const [active, setActive] = useState<boolean>();
        const {setFabOrderTypeChosen} = useFabApp()
        const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if(fabOrderType) {
                setFabOrderTypeChosen(fabOrderType)
            }
            if (onClick) {
                onClick(e);
            }
            if (route) {
                navigate(route);
            }
        }
        useEffect(() => {
            if (route) {
                const routeWithoutSlashes = route.replace(/\//g, ''); // Retirer tous les '/'
                const urlWithoutSlashes = document.URL.replace(/\//g, ''); // Retirer tous les '/'
                const regex = new RegExp(`${routeWithoutSlashes}($|[^a-zA-Z0-9_])`);
                const match = urlWithoutSlashes.match(regex);
                if (match != null) {
                    setActive(true)
                    return
                }
            }
            setActive(false)
        }, [navigate]);
        return (
            <button
                onClick={handleClick}
                className={`w-full flex duration-150 m-2 cursor-pointer ${active ? "bg-fab_congress-blue-500 text-white shadow-md" : "hover:bg-fab_congress-blue-500 hover:shadow-md"} `}>
                <div className="rounded-sm flex p-1">
                    {leftIcon}
                    <div className="h-max-full pr-1 pl-1">{text}</div>
                </div>
            </button>
        )
    }