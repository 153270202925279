import {
    AirportShuttle,
    AppSettingsAlt,
    AssignmentReturned, AssignmentTurnedIn,
    ContactPhone,
    DirectionsCar, LeakAdd,
    LocalShipping, ThumbUp
} from "@mui/icons-material";
import React from "react";

export const iconsForFabOrderType = [
    {label: "ic_app_settings_alt", icon: <AppSettingsAlt /> },
    {label: "ic_local_shipping", icon: <LocalShipping /> },
    {label: "ic_thumb_up", icon: <ThumbUp /> },
    {label: "ic_assignment_returned", icon: <AssignmentReturned /> },
    {label: "ic_assignment_turned_in", icon: <AssignmentTurnedIn /> },
    {label: "ic_leak_add", icon: <LeakAdd /> }
]

export const getIconForLabel = (label: string) => {
    const get = iconsForFabOrderType.find((icon) => icon.label === label)
    if (get) {
        return get
    } else {
        return iconsForFabOrderType.at(0)
    }
}