import {TextInput} from "../../ui/inputs/TextInput";
import {Button} from "../../ui/inputs/Button";
import {CheckCircleIcon, PlusCircleIcon, TrashIcon} from "@heroicons/react/24/solid";
import React, {FC, MouseEventHandler, useEffect, useState} from "react";
import {FModal} from "../../ui/elements/FModal";
import {TagInfoType, TagInfoTypeValue} from "../../../interfaces/TagInfoType";
import {useTagInfoTypesApi} from "../../../api/tagInfoTypesApi";
import {IFModal} from "../../ui/interfaces/elements/IFModal";
import {DataType} from "../../../interfaces/DataType";
export const TagInformationsEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const [valuesJSXRender, setValuesJSXRender] = useState<JSX.Element[]>([]);
    const [values, setValues] = useState<{label: string, rfid: string}[]>([])
    const [infoLabel, setInfoLabel] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [selectedDatatype, setSelectedDatatype] = useState<string>("TEXT")
    const {addTagInfoType, loading} = useTagInfoTypesApi()
    useEffect(() => {
        let newJSXValues: JSX.Element[] = [];
        values.map( (value, index) => {
            newJSXValues.push(
                <>
                    <div className="flex w-full h-12 justify-items-center" key={index} data-indexid={index}>
                        <div className="w-2/6 m-auto">
                            <p className="m-auto">{index}</p>
                        </div>
                        <div className="w-2/6 m-auto">
                            <TextInput value={value.label} onChange={onLabelChange} className={"w-3/5 m-auto"}/>
                        </div>
                        <div className="w-2/6 m-auto">
                            <TextInput value={value.rfid} onChange={onRfidValueChange} className={"w-3/5 m-auto h-8"}/>
                        </div>
                        <div className="h-2/6 m-auto bg-gray-500 w-0.5"></div>

                        <div className="w-1/6 h-1/4 flex">
                            <div data-indexid={index} className="w-full flex m-auto">
                                <Button onClick={handleDeleteValue}
                                        soloIcon={<TrashIcon className={"h-12 w-12 p-4"}/>}/>
                            </div>

                        </div>

                    </div>
                    <div className="w-full h-0.5 m-auto mt-2 mb-2 bg-gray-500"></div>

                </>
            )
        })
        setValuesJSXRender(newJSXValues);
    }, [values]);
    const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        let id = e.target.parentElement?.parentElement?.parentElement?.getAttribute('data-indexid')
        if (id != undefined) {
            let intId = parseInt(id);
            setValues(current => {
                const newArray = [...current];
                newArray[intId].label = e.target.value
                return newArray;
            })
        }
    }
    const onRfidValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        let id = e.target.parentElement?.parentElement?.parentElement?.getAttribute('data-indexid')
        if (id != undefined) {
            let intId = parseInt(id);
            setValues(current => {
                const newArray = [...current];
                newArray[intId].rfid = e.target.value
                return newArray;
            })
        }
    }

    const handleDeleteValue: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        let id = e.currentTarget.parentElement?.parentElement?.getAttribute('data-indexid')
        if (id != undefined) {
            let intId = parseInt(id);
            setValues(current => {
                const newArray = [...current];
                newArray.splice(intId, 1);
                return newArray;
            });
        }
        return;
    }
    const handleSelectDatatype = (e: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(e.currentTarget.value)
        setSelectedDatatype(e.currentTarget.value)
    }
    const handleAddNewValueTable = () => {
        setValues([...values, {
            label:"",
            rfid: ""
        }])
    }
    const handleValid = () => {
        if (infoLabel == "") {
            setErrorMessage("Libellé de l'information obligatoire")
            return;
        }
        try {
            const tagInfoTypeValues: TagInfoTypeValue[] = []
            let needRfid = values.findIndex(value => value.rfid != "") > -1
            values.map( (info: {label: string, rfid: string}, index: number) => {
                if (info.label == ""){
                    setErrorMessage("Label "+ index +" obligatoire");
                    throw {};
                }
                if (needRfid && info.rfid == ""  ) {
                    setErrorMessage("Valeur RFID "+ index + " obligatoire");
                    throw {};
                }
                tagInfoTypeValues.push({
                    id: 0,
                    label: info.label,
                    rfidReference: info.rfid,
                    order: index
                })
            })
            const newTagInfoType: TagInfoType = {
                id: 0,
                label: infoLabel,
                Values: tagInfoTypeValues,
                datatype: selectedDatatype
            }
            addTagInfoType(newTagInfoType).then(() => {
                if (setModalOpen) setModalOpen(false)
            })
        } catch {}

    }
    return (

        <FModal className="fab_editor_modal !h-5/6 !justify-center flex flex-col" canClose onClose={onClose} title={"Ajouter une information pour un TAG"}>
            <>
                <p className="bg-fuchsia-100 rounded">
                    <b>Si pas de valeur dans la liste, elle sera saisissable part l'utilisateur </b></p>
                <div className={"w-full m-auto p-1 mt-5 mb-5 flex flex-col gap-5"}>
                    <div className="w-full h-4">
                        <span className="text-red-700">{errorMessage != "" ? errorMessage : null}</span>
                    </div>
                    <TextInput value={infoLabel} setterChange={setInfoLabel}
                               className={"fab_editor_modal_input"}
                               label={"Libellé de l'information"}/>
                </div>
                <div className="flex gap-2 w-2/3 m-auto p-2">
                    <Button text={"Ajouter une valeur"} onClick={handleAddNewValueTable}
                            leftIcon={<PlusCircleIcon className="h-12 w-12 p-2"/>}/>
                        <div className="flex w-2/3">
                            <p className="m-auto">Type de donnée : </p>
                            <select className="fab_button m-auto p-1 fab_input" onChange={handleSelectDatatype}>
                                {
                                    Object.keys(DataType).map((key) => {
                                            // @ts-ignore
                                            return (<option value={key}>{DataType[key]}</option>);
                                        }
                                    )
                                }
                            </select>
                        </div>
                    </div>


                    <div
                        className="w-11/12 m-auto h-12 flex border-t border-r border-l rounded-t-md bg-fab_congress-blue-500">
                        <span className="w-2/6 m-auto">#</span>
                        <div className="h-2/3 m-auto bg-gray-500 w-0.5"></div>
                        <span className="w-2/6 m-auto">Label</span>
                        <div className="h-2/3 m-auto bg-gray-500 w-0.5"></div>
                        <span className="w-2/6 m-auto">Valeur RFID (Optionnel)</span>
                        <div className="h-2/3 m-auto bg-gray-500 w-0.5"></div>
                        <span className="w-1/6 m-auto">Actions</span>
                    </div>
                    <div
                        className="w-11/12 h-full border-l border-r border-b shadow-2xl rounded-b-md overflow-y-auto m-auto pt-2">
                        {valuesJSXRender && valuesJSXRender.length > 0 &&
                            <>{
                                valuesJSXRender.map(elt => {
                                    return <>{elt}</>;
                                })
                            }</>
                        }
                    </div>
                    <div className="flex flex-col gap-2 w-1/3 m-auto p-2">
                        <Button text={"Valider"}
                                leftIcon={<CheckCircleIcon className="h-12 w-12 p-2"/>} loading={loading}
                                onClick={handleValid}/>
                    </div>
                </>
        </FModal>
)
}