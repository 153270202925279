import Logo from '../../assets/logo_fabtracer.png'
export const NotFound = () => {
    return(
        <div className='h-full w-full flex flex-col items-center justify-around bg-fab_app_container'>
            <img src={Logo} alt="Logo de fabtracer" />
            <h1 className="text-3xl font-bold underline">
                Erreur 404 - Cette page n'existe pas
            </h1>
        </div>
            
    )
}