import {FModal} from "../../ui/elements/FModal";
import React, {FC, SyntheticEvent, useState} from "react";
import {IFModal} from "../../ui/interfaces/elements/IFModal";
import {TextInput} from "../../ui/inputs/TextInput";
import {Button} from "../../ui/inputs/Button";
import {Autocomplete, TextField} from "@mui/material";
import {Role} from "../../../interfaces/Role";
import {User} from "../../../interfaces/User";
import {useRolesApi, useUsersApi} from "../../../api/base_apis";

export const UserEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const [fullName, setFullName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [login, setLogin] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [mobileLogin, setMobileLogin] = useState<string>("")
    const [mobilePassword, setMobilePassword] = useState<string>("")
    const [roleId, setRoleId] = useState<number>(0)
    const {data: roles} = useRolesApi()
    const {addItem: addUser, loading} = useUsersApi()
    const [formErrors, setFormErrors] = useState<string[]>([])
    const onCloseFormErrors = () => {
        setFormErrors([])
    }
    const onValidUser = () => {
        const errors = []
        if (fullName == "") errors.push("Nom obligatoire")
        if (login == "") errors.push("Identifiant WEB obligatoire")
        if (password == "") errors.push("Mot de passe WEB obligatoire")
        if (mobileLogin == "") errors.push("Identifiant MOBILE obligatoire")
        if (mobilePassword == "") errors.push("Mot de passe MOBILE obligatoire")
        if (roleId === 0) errors.push("Mot de passe MOBILE obligatoire")
        // addRole(newSite)
        if (errors.length > 0) {
            setFormErrors(errors)
        } else {
            const newUser: User = {
                id: 0,
                email: email,
                fullName: fullName,
                login: login,
                password: password,
                mobileLogin: mobileLogin,
                mobilePassword: mobilePassword,
                roleId: roleId
            }
            addUser(newUser).then(r =>  {
                if (setModalOpen) setModalOpen(false)
            })

        }
    }
    const onRoleChange = (e: SyntheticEvent<Element, Event>, newValue: Role | null) => {
        e.preventDefault();
        setRoleId(newValue?.id ?? 0)
    }
    return(<FModal title={"Ajout d'un utilisateur"}
                   className="fab_editor_modal"
                   canClose onClose={onClose}>
            <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
                <TextInput value={fullName} setterChange={setFullName} label={"Nom complet : "}
                           className={"flex gap-10 align-middle justify-center w-2/3 m-auto mt-5"}/>
                <TextInput value={email} setterChange={setEmail} label={"E-mail : "}
                           className={"flex gap-10 align-middle justify-center w-2/3 m-auto mt-5"}/>
                <TextInput value={login} setterChange={setLogin} label={"Identifiant WEB : "}
                           className={"flex gap-10 align-middle justify-center w-2/3 m-auto mt-5"}/>
                <TextInput value={password} setterChange={setPassword} label={"Mot de passe WEB : "}
                           className={"flex gap-10 align-middle justify-center w-2/3 m-auto mt-5"}/>
                <TextInput value={mobileLogin} setterChange={setMobileLogin} label={"Identifiant MOBILE : "}
                           className={"flex gap-10 align-middle justify-center w-2/3 m-auto mt-5"}/>
                <TextInput value={mobilePassword} setterChange={setMobilePassword} label={"Mot de passe MOBILE : "}
                           className={"flex gap-10 align-middle justify-center w-2/3 m-auto mt-5"}/>
                <div className="fab_editor_modal_input !justify-around">
                    <label className={"w-1/3 text-left border-b"}>Rôle :</label>
                    <Autocomplete
                        id="combo-box-role"
                        options={roles}
                        className={"w-2/3 fab_input !border-0"}
                        onChange={onRoleChange}
                        renderInput={
                            (params) =>
                                <TextField {...params} label="" variant={'standard'}/>
                        }
                    />
                </div>
            </div>
            <Button onClick={onValidUser} loading={loading} className="mt-2 mb-2" text={"Valider"}/>
            {
                formErrors && formErrors.length > 0 &&
                <FModal
                    className="bg-white w-2/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl"
                    canClose onClose={onCloseFormErrors} title={"Erreurs"}>
                    {
                        formErrors.map(error => (
                            <p className="p-2 m-2 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error}</p>
                        ))
                    }
                </FModal>
            }
    </FModal>
)

}