// noinspection JSDeprecatedSymbols

import {FModal} from "../../ui/elements/FModal";
import React, {FC, useEffect, useState} from "react";
import {IFModal} from "../../ui/interfaces/elements/IFModal";
import {TextInput} from "../../ui/inputs/TextInput";
import {Button} from "../../ui/inputs/Button";
import {FabOrder, FabOrderInfos} from "../../../interfaces/FabOrder";
import {useFabOrdersApi} from "../../../api/base_apis";
import {useFabApp} from "../../../hooks/useFabApp";
import {FabOrderType, FabOrderTypeInfo} from "../../../interfaces/FabOrderType";
import {NumberInput} from "../../ui/inputs/NumberInput";
import {CheckCircleIcon} from "@heroicons/react/24/solid";

interface FabOrderEditorProps extends IFModal {
    orderFabType: FabOrderType
}

export const  FabOrderEditor: FC<FabOrderEditorProps> = ({onClose, setModalOpen, orderFabType}) => {
    const [label, setLabel] = useState<string>("")
    const {addItem: addFabOrder, loading} = useFabOrdersApi()
    const {companyChosen, siteChosen, fabOrderTypeChosen} = useFabApp()
    const [itemQuantity, setItemQuantity] = useState<number>(0)
    const [orderBatch, setOrderBatch] = useState<string>("")
    const [infosInOrderType, setinfosInOrderType] = useState<FabOrderTypeInfo[]>()
    const [infosInOrderValuesRender, setInfosInOrderValuesRender] = useState<FabOrderInfos[]>([])
    const onValidFabOrder = () => {
        const newFabOrder: FabOrder = {
            Infos: infosInOrderValuesRender,
            Lines: [{
                id: 0,
                quantity: itemQuantity,
                quantityDone: 0,
                batch: orderBatch,
                fabOrderId: 0,
                Movements: [],
            }],
            siteId: siteChosen!.id,
            fabOrderTypeId: fabOrderTypeChosen!.id,
            id: 0,
            internalOrderId: label,
        }
        addFabOrder(newFabOrder).then(r => {
            if (setModalOpen) {
                setModalOpen(false);
            }
        })
    }
    const onChangeSelect = (e: any) => {
        e.preventDefault()
        let opt = e.target.options[e.target.value]
        if (!opt) return
        let typeId = opt.getAttribute('data-typeid')
        let valueId = opt.getAttribute('data-valueid')
        let type = orderFabType.Infos.find(i => i.tagInfoTypeId == typeId)
        if (!type) return
        let value = type.TagInfo?.Values.find(v => v.id == valueId)
        if (!value) return
        let origInfosInOrderValues = infosInOrderValuesRender
        let infoInOrderValue = origInfosInOrderValues.findIndex(v => v.tagInfoTypeId == typeId)
        if (infoInOrderValue > -1) {
            origInfosInOrderValues.splice(infoInOrderValue, 1)
            let newRender: FabOrderInfos = {
                id: 0,
                tagInfoTypeId : type.tagInfoTypeId ?? 0,
                tagInfoTypeValueId : parseInt(valueId) ?? 0,
                render : <>
                    <span className={"border-b"}>{type.label} :</span>
                    <span>{value.rfidReference}</span>
                </>
            }
            setInfosInOrderValuesRender([...origInfosInOrderValues, newRender])
        }
        // } return
        // infoInOrderValue.value = valueId
        // infoInOrderValue.rfidReference = value.rfidReference

    }
    useEffect(() => {
        if (!orderFabType) return
        let fetchTagInfos = orderFabType.Infos.filter(i => i.tagInfoTypeId != undefined)
        let newInfosInOrderValues : FabOrderInfos[] = []
        if (fetchTagInfos.length > 0) {
            fetchTagInfos.map(i => {
                if (i.TagInfo && i.TagInfo.Values.length > 0) {
                    newInfosInOrderValues.push({
                        id: 0,
                        tagInfoTypeId : i.TagInfo.id,
                        tagInfoTypeValueId : i.TagInfo.Values[0].id ?? 0,
                        render : <>
                            <span className={"border-b"}>{i.label} :</span>
                            <span>{i.TagInfo.Values[0].rfidReference}</span>
                        </>
                    })
                }
            })
        }
        setInfosInOrderValuesRender(newInfosInOrderValues)
        setinfosInOrderType(fetchTagInfos)
    }, [orderFabType]);
    return(<FModal
        className="fab_editor_modal"
        canClose onClose={onClose} title={`Création d'un ordre : ${orderFabType.label}`}>
            <div className={"w-10/12 m-auto p-1"}>
                <div className="w-full">
                    <span>Société : <b>{companyChosen?.label}</b></span>
                </div>
                <div className="w-full">
                    <span>Site : <b>{siteChosen?.label}</b></span>
                </div>
            {/*<TextInput value={rfidReference} setterChange={setRfidReference} label={"Valeur d'encodage RFID de l'OF"}/>*/}
            </div>
            <div className={"w-full m-auto p-1 mt-10"}>
                <TextInput value={label} setterChange={setLabel} label={"Libellé de l'ordre de fabrication : "}  className={"flex gap-10 align-middle justify-center w-2/3 m-auto"}/>
                <NumberInput value={itemQuantity} setterChange={setItemQuantity} label={"Quantité (Aucune limite si 0) : "} className={"flex gap-10 align-middle justify-center w-2/3 m-auto mt-5"}/>
                {orderFabType.useBatch ? <TextInput value={orderBatch} setterChange={setOrderBatch} label={"Lot (3 caractères) : "} className={"flex gap-10 align-middle justify-center w-2/3 m-auto mt-5"}/> : <></>}
            {/*</div>*/}
            {/*<div className={"w-full m-auto flex flex-col p-1"}>*/}
                {
                    infosInOrderType && infosInOrderType.length > 0 &&
                    infosInOrderType.map((typeInfo) => {
                        if (typeInfo.TagInfo) {
                            return <div className="flex gap-10 align-middle justify-center w-2/3 m-auto mt-5">
                                <div className="w-1/3 text-left"> {typeInfo.TagInfo.label}</div>
                                {!typeInfo.isUserInput ?
                                    typeInfo.TagInfo.Values.length > 0 &&
                                        <select className="w-2/3 fab_button fab_input" onChange={onChangeSelect}>
                                            {
                                                typeInfo.TagInfo.Values.map((value, index) => <option
                                                    data-typeid={value.tagInfoTypeId} data-valueid={value.id}
                                                    value={index}>{`${value.label}(${value.rfidReference})`}</option>)
                                            }
                                        </select>
                                        ||
                                    <TextInput />
                                    :
                                    <p>Saisie utilisateur</p>
                                }

                            </div>
                        } else return false;

                    })
                }
            </div>
                <div className={"w-10/12 m-auto flex flex-col p-1 justify-center"}>
                    {
                        orderFabType.useEncoding && orderFabType.EncodingFormat &&
                        <>
                            <span className="">Aperçu encodage</span>
                            <div className="w-full flex gap-1 text-xl justify-center">

                                    {[...Array(10)].map((x, i) =>
                                        {
                                            if (orderFabType.EncodingFormat!.orderSite == i)
                                                return (<div className={"border flex flex-col"}>
                                                    <span className={"border-b"}>Site : </span>
                                                    {siteChosen?.rfidReference != "" ? siteChosen?.rfidReference : "MANQUANT"}
                                                </div>)
                                            else if (orderFabType.EncodingFormat!.orderCompany == i)
                                                return (<div className={"border flex flex-col"}>
                                                    <span className={"border-b"}>Société : </span>
                                                    {companyChosen?.rfidReference != "" ? companyChosen?.rfidReference : "MANQUANT"}
                                                </div>)
                                            else if (orderFabType.EncodingFormat!.orderBatch == i)
                                                return (<div className={"border flex flex-col"}>
                                                    <span className={"border-b"}>Lot : </span>
                                                    {orderBatch != "" ? orderBatch : "MANQUANT"}
                                                </div>)
                                            else if (orderFabType.EncodingFormat!.orderTagBatchId == i)
                                                return (<div className={"border flex flex-col"}>
                                                    <span className={"border-b"}>Lot : </span>
                                                    {"000001"}
                                                </div>)
                                            else {
                                                const info = orderFabType.Infos.find((inf) => inf.order === i);
                                                return (
                                                    info && <div className={"border flex flex-col"}>
                                                        <span className={"border-b"}>{info?.TagInfo?.label}</span>
                                                        {infosInOrderType ? infosInOrderValuesRender.find(i => info.id == i.id)?.id : "MANQUANT"}
                                                    </div>
                                                );
                                            }
                                        }
                                    )}

                            </div>
                        </>
                    }
            </div>
            <Button onClick={onValidFabOrder} leftIcon={<CheckCircleIcon />} loading={loading} text={"Valider"} className={"mt-2 mb-1 w-1/12"}/>
    </FModal>
)

}