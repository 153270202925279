import React, {FC, useEffect, useState} from 'react';
import {FabAppContext} from '../hooks/useFabApp';
import useLocalStorage from '../hooks/useLocalStorage';
import {ISite} from "../interfaces/ISite";
import {ModalSiteChooser} from "../components/pages/editors/ModalSiteChooser";
import {ICompany} from "../interfaces/ICompany";
import {FabOrderType} from "../interfaces/FabOrderType";
import {User} from "../interfaces/User";
interface FabAppProviderProps {
  children?: React.ReactNode;
}

const FabAppProvider: FC<FabAppProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [tokenLocalItemValue] = useLocalStorage("fabToken", "");
  const [chooseSiteIndexModalOpen, setChooseSiteIndexModalOpen] = useState<boolean>(false);
  const addNotification = (notification: Notification) => {
    setNotifications((prevNotifications) => [...prevNotifications, notification]);
  };
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [gotToken, setGotToken] = useState<boolean>(false)
  const [isAuthenticated, setIsAuth] = useState<boolean>(false)
  const [user, setUser] = useState<User | null>(null)
  const [error, setError] = useState<string>('')
  const [companyChosen, setCompanyChosen] = useState<ICompany | null>(null)
  const [siteChosen, setSiteChosen] = useState<ISite | null>(null)
  const [fabOrderTypeChosen, setFabOrderTypeChosen] = useState<FabOrderType | null>(null)
  const [specificIdentifierChosen, setSpecificIdentifierChosen] = useState<number | null>(null)


  useEffect(() => {
    if (tokenLocalItemValue != '') setGotToken(true)
    else setGotToken(false)
  }, [tokenLocalItemValue]);
  useEffect(() => {
    if(!isAuthenticated) return;
    if (siteChosen == null) setChooseSiteIndexModalOpen(true)
    else setChooseSiteIndexModalOpen(false)
  }, [siteChosen, isAuthenticated]);
  const handleRedirectCallback = (...p: any) => {};

  return (
    <>
      <FabAppContext.Provider value={{
        isLoading,
        gotToken,
        isAuthenticated,
        user,
        error,
        siteChosen,
        fabOrderTypeChosen,
        specificIdentifierChosen,
        companyChosen,
        handleRedirectCallback,
        notifications,
        addNotification,
        setIsAuth,
        setUser,
        setSiteChosen,
        setFabOrderTypeChosen,
        setSpecificIdentifierChosen,
        setCompanyChosen}}>
        {children}
        { chooseSiteIndexModalOpen && <ModalSiteChooser /> }
      </FabAppContext.Provider>
    </>
  )
};
export default FabAppProvider;