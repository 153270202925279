import {Button} from "../ui/inputs/Button";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import React, {useEffect, useState} from "react";
import {GridRowModesModel} from "@mui/x-data-grid";
import {useFabOrderTypesApi} from "../../api/base_apis";
import {FDataGrid} from "../ui/elements/FDataGrid";
import {FModal} from "../ui/elements/FModal";
import {FabOrderTypeEditor} from "./editors/FabOrderTypeEditor";

export const FabOrderTypesPage = () => {
    const {data: types, loading: loadinTtypes, error: error, resetApiError, updateItem: updateType, deleteItem: deleteType, refreshItems: refreshTypes} = useFabOrderTypesApi();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalCreateFabOrderType, setModalCreateFabOrderType] = useState<boolean>(false)
    const columns: any[] = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'label', headerName: 'Nom', type: 'string', width: 410, editable:true},
        { field: 'EncodingFormat', headerName: 'Encodage', width: 410, valueGetter: (params: any) => params ? params.label : "Aucun" }
        // { field: 'checkCompany', headerName: 'Société', type: 'boolean', width: 25, editable:true},
        // { field: 'checkSite', headerName: 'Acces Web', type: 'boolean', width: 25, editable:true},
        // { field: 'checkTeams', headerName: 'Equipe', type: 'boolean', width: 25, editable:true},
        // { field: 'checkUser', headerName: 'Utilisateur', type: 'boolean', width: 100, editable:true},
        // { field: 'useTag', headerName: 'Tag Existant', type: 'boolean', width: 25, editable:true},
        // { field: 'useOrder',headerName: 'Part Ordre', type: 'boolean', width: 25, editable:true},
        // { field: 'useBatch',headerName: 'Part Lot', type: 'boolean', width: 25, editable:true},
        // { field: 'encodingFormatId',headerName: 'Encodage', type: 'singleSelect', width: 100, editable:true},
    ]
    const openModalCreateFabOrderType = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!modalCreateFabOrderType) setModalCreateFabOrderType(true);
    }
    const onCloseModal = (e: React.MouseEvent) => {
        setModalCreateFabOrderType(false)
        refreshTypes().then()
    }
    const resetError = () => {
        resetApiError()
    }
    useEffect(() => {
        if (!modalCreateFabOrderType) refreshTypes().then();
    }, [modalCreateFabOrderType]);
    return (
        <div className="w-full h-full bg-fab_app_container flex flex-col">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">PARAMÈTRAGE DES TYPES D'ORDRE</span>
            </div>
            <div className="w-1/4 p-5 m-auto">
                <Button text={"Ajouter un type d'ordre"} leftIcon={<PlusCircleIcon/>} onClick={openModalCreateFabOrderType} />
            </div>
            { rowModesModel &&
                <div className="w-full flex-1">
                    <FDataGrid
                        rowModesModel={rowModesModel}
                        setRowModesModel={setRowModesModel}
                        updateRow={updateType}
                        deleteRow={deleteType}
                        rows={types}
                        loading={loadinTtypes}
                        columns={columns}
                        confirmDeleteText={"Attention, la suppression d'un type d'ordre entraîne la perte de tous ses ordres de fabrication"}
                    />
                </div>
            }
            { error &&
                <FModal className="bg-white w-2/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl" canClose onClose={resetError} title={"Erreur"}>
                    <div className={"w-full flex flex-col mt-4"}>
                        <p className="p-5 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error.message}</p>
                    </div>
                </FModal>
            }
            {
                modalCreateFabOrderType &&
                <FabOrderTypeEditor onClose={onCloseModal} setModalOpen={setModalCreateFabOrderType}/>
            }
            {/*{*/}
            {/*    modalAddRoleOpen && <RoleEditor onClose={onCloseAddRoleModal} setModalOpen={setModalAddRoleOpen}/>*/}
            {/*}*/}
        </div>
    )
}