import React, {FC, useState} from "react";
import {IFModal} from "../../ui/interfaces/elements/IFModal";
import {TextInput} from "../../ui/inputs/TextInput";
import {FModal} from "../../ui/elements/FModal";
import {User} from "../../../interfaces/User";
import {Button} from "../../ui/inputs/Button";
import {useUsersApi} from "../../../api/base_apis";
import {CheckCircleIcon} from "@heroicons/react/24/solid";


export const PasswordEditor: FC<{user?: User} & IFModal> = ({user, onClose, setModalOpen}) => {
    const [mobilePassword, setMobilePassword] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const {updateItem: updateUser, loading} = useUsersApi()
    const onValidPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        const copyUser = user
        if (!copyUser) return
        if (password != "") copyUser!.password = password
        if (mobilePassword != "") copyUser!.mobilePassword = mobilePassword
        if (setModalOpen) {
            updateUser(copyUser).then(() => setModalOpen(false));
        }
    }
    return(
        <FModal title={"Modification des mots de passes pour l'utilisateur " + user?.login}
                className="bg-white w-full h-3/6 rounded-md m-auto text-center shadow-2xl text-xl"
                canClose onClose={onClose}>
            <div className={"w-full m-auto p-1 mt-10"}>
                <TextInput value={password} setterChange={setPassword} label={"Mot de passe WEB : "}
                           className={"flex gap-10 align-middle justify-center w-2/3 m-auto"}/>
                <TextInput value={mobilePassword} setterChange={setMobilePassword} label={"Mot de passe MOBILE : "}
                           className={"flex gap-10 align-middle justify-center w-2/3 m-auto mt-5"}/>
                <Button onClick={onValidPassword} leftIcon={<CheckCircleIcon />} text={"Valider"} loading={loading} className={"mt-2 mb-1 w-1/12"}/>
            </div>
        </FModal>
)
}